<template>
  <bar-card v-if="misc.bands">
    <div class="flex flex-wrap justify-center">
      <router-link class="title md:text-xl" to="/markets">
        IV BANDS
      </router-link>
    </div>
    <div class="bar-body flex flex-col my-1 sm:my-2">
      <div class="w-full h-full" :class="lxvx.day_class">
        <div class="bar-item justify-between">
          <div class="">LXVX HIGH</div>
          <div>
            {{ lxvx.day_hig | formatNumber }}
          </div>
        </div>
        <div class="bar-item justify-between">
          <div class="w-full">LXVX LOW</div>
          <div>{{ lxvx.day_low | formatNumber }}</div>
        </div>
        <div class="bar-item justify-between">
          <div class="w-full">DAY (%)</div>
          <div>{{ lxvx.day_rate | percentage2 }}</div>
        </div>
      </div>
      <div class="w-full h-full" :class="lxvx.week_class">
        <div class="bar-item justify-between">
          <div class="w-full">WEEK HIGH</div>
          <div>{{ lxvx.week_hig | formatNumber }}</div>
        </div>
        <div class="bar-item justify-between">
          <div class="w-full">WEEK LOW</div>
          <div>{{ lxvx.week_low | formatNumber }}</div>
        </div>
        <div class="bar-item justify-between">
          <div class="w-full">WEEK (%)</div>
          <div>{{ lxvx.week_rate | percentage2 }}</div>
        </div>
      </div>
    </div>

    <div class="border rounded mt-8">
      <div class="flex flex-wrap justify-center">
        <a
          class="uppercase md:text-xl text-center pt-2 font-bold"
          href="https://www.bitmex.com/app/trade/XBTUSD"
          target="_blank"
        >
          WEEK GAP
        </a>
      </div>
      <div class="my-2 text-xl font-hairline text-center font-black">
        <div class="text-xs" :class="blue ? 'text-blue-600' : ''">
          {{ satPrice | formatNumber }} {{ _date(satTimestamp, '土') }}
        </div>
        <div class="text-xs">
          {{ monPrice | formatNumber }} {{ _date(monTimestamp, '月') }}
        </div>
        <div class="text-xs" :class="red ? 'text-red-600' : ''">
          {{ standard | formatNumber }} (標準)
        </div>
      </div>
    </div>

    <div class="border rounded mt-8">
      <div class="flex flex-wrap justify-center">
        <a
          class="uppercase md:text-xl text-center pt-2 font-bold"
          href="https://ftx.com/spot-margin/lending"
          target="_blank"
        >
          FTX Next FR
        </a>
      </div>
      <div class="my-2 text-xl font-hairline text-center font-black">
        <div>{{ ftxFR | formatNumber }} %</div>
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/markets/BarCard'
import { _class } from './index.js'

export default {
  name: 'IvBand',
  components: {
    BarCard,
  },
  props: {
    weekGap: {
      type: Object,
      required: true,
    },
    price: {
      type: Number,
      default: 1,
    },
    discord: {
      type: Object,
      default: () => ({}),
    },
    misc: {
      type: Object,
      default: () => ({}),
    },
    fundingRate: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    standard() {
      return this.weekGap.standard.price
    },
    red() {
      return !this.weekGap.standard.reach
    },
    blue() {
      return !this.weekGap.sat.reach
    },
    monPrice() {
      return this.weekGap.mon.price
    },
    monTimestamp() {
      return this.weekGap.mon.timestamp
    },
    satPrice() {
      return this.weekGap.sat.price
    },
    satTimestamp() {
      return this.weekGap.sat.timestamp
    },
    lxvx() {
      /*
        https://thanmtsvcb.slack.com/archives/DL97S0GUB/p1617839510003100
        ・IV BANDS DAYとWEEKで２分割
        DAY
          対象：変化率
          上限：+100%：赤
          下限：0%：緑
        WEEK
          対象：変化率
          上限：+100%：赤
          下限：0% ：緑
      */
      const res = {
        ...this.discord.lxvx,
        day_low: this.misc.bands[2],
        day_hig: this.misc.bands[1],
      }
      res.day_rate = (this.price - res.day_low) / (res.day_hig - res.day_low)
      res.week_rate =
        (this.price - res.week_low) / (res.week_hig - res.week_low)
      res.day_class = _class(-res.day_rate, { mid: -0.5, diff: 0.5 })
      res.week_class = _class(-res.week_rate, { mid: -0.5, diff: 0.5 })
      return res
    },
    ftxFR() {
      return (Math.floor(this.fundingRate.rate * 1e3) / 1e3) * 100
    },
  },
  methods: {
    _date(ts, weekday) {
      const date = new Date(ts)
      const month = 1 + date.getMonth()
      const day = date.getDate()
      const hour = date.getHours()
      return `(${month}/${day}) ${weekday} ${hour}:00`
    },
  },
}
</script>

<style lang="css" src="./style.css" scoped />
