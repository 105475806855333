function getRandomColor() {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

function getTreasuriesTime(date) {
  return new Date(date).getTime()
}

const defaultCompanyChartOption = {
  visible: false,
  tooltip: { valueSuffix: ' BTC' },
  data: [],
  yAxis: 1,
}

const url = 'https://bitcointreasuries.org'
const title = { text: `<a href="${url}">BitCoin Treasuries</a>` }

export default {
  chart: { height: 600 },
  legend: {
    enabled: true,
  },
  rangeSelector: { inputEnabled: false },
  title,
  yAxis: [
    {
      visible: true,
      opposite: false,
      title: { text: 'BTC' },
    },
    {
      visible: true,
      opposite: true,
      title: { text: '' },
    },
  ],
  xAxis: {
    type: 'datetime',
    range: 30 * 86400000,
  },
  plotOptions: {},
  series: [
    {
      name: 'Total',
      color: '#FA8072',
      tooltip: { valueSuffix: ' BTC' },
      data: [],
    },
  ],
}

export { getRandomColor, getTreasuriesTime, defaultCompanyChartOption }
