<template>
  <bar-card v-if="binanceAccount.ratio">
    <div class="flex flex-wrap justify-center">
      <a
        class="uppercase md:text-xl font-bold text-center pt-2"
        href="https://www.binance.com/en/futures/funding-history/4"
        target="_blank"
      >
        LONG / SHORT
      </a>
    </div>

    <div class="bar-body my-1 sm:my-2 divide-y space-y-1">
      <div class="bar-item flex flex-col" :class="binanceAccount.class">
        <div class="pl-2 w-full flex justify-start font-bold underline">
          BINANCE: Top Trader (Accounts)
        </div>
        <div
          class="w-5/6 flex flex-col"
          v-html="formatBinanceAccount(binanceAccount)"
        />
      </div>
      <div class="bar-item flex flex-col" :class="binancePosition.class">
        <div class="pl-2 w-full flex justify-start font-bold underline">
          BINANCE: Top Trader (Position)
        </div>
        <div
          class="w-5/6 flex flex-col"
          v-html="formatBinanceAccount(binancePosition)"
        />
      </div>
      <div class="bar-item flex flex-col" :class="binanceGlobalAccount.class">
        <div class="pl-2 w-full flex justify-start font-bold underline">
          BINANCE: Global Traders
        </div>
        <div
          class="w-5/6 flex flex-col"
          v-html="formatBinanceAccount(binanceGlobalAccount)"
        />
      </div>
      <div class="bar-item flex flex-col" :class="bitmexGlobalAccount.class">
        <div class="pl-2 w-full flex justify-start font-bold underline">
          BITMEX: Global Traders
        </div>
        <div
          class="w-5/6 flex flex-col"
          v-html="formatBinanceAccount(bitmexGlobalAccount)"
        />
      </div>
      <div class="bar-item flex flex-col" :class="bitfinexGlobalAccount.class">
        <div class="pl-2 w-full flex justify-start font-bold underline">
          BITFINEX: Global Traders
        </div>
        <div
          class="w-5/6 flex flex-col"
          v-html="formatBinanceAccount(bitfinexGlobalAccount)"
        />
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/markets/BarCard'
import { _bCacl } from './index.js'

export default {
  name: 'LongShort',
  components: {
    BarCard,
  },
  props: {
    lsChecker: {
      type: Object,
      required: true,
    },
  },
  computed: {
    binanceRatio() {
      if (this.lsChecker.binance) {
        return this.lsChecker.binance.ratio
      }
      return {}
    },
    binanceAccount() {
      return _bCacl(this.binanceRatio.topLongShortAccountRatio)
    },
    binancePosition() {
      return _bCacl(this.binanceRatio.topLongShortPositionRatio)
    },
    binanceGlobalAccount() {
      return _bCacl(this.binanceRatio.globalLongShortAccountRatio)
    },
    bitmexRatio() {
      if (this.lsChecker.bitmex) {
        return this.lsChecker.bitmex.ratio
      }
      return {}
    },
    bitmexGlobalAccount() {
      return _bCacl(this.bitmexRatio.globalLongShortAccountRatio)
    },
    bitfinexRatio() {
      if (this.lsChecker.bitfinex) {
        return this.lsChecker.bitfinex.ratio
      }
      return {}
    },
    bitfinexGlobalAccount() {
      return _bCacl(this.bitfinexRatio.globalLongShortAccountRatio)
    },
  },
  methods: {
    formatBinanceAccount({ curent, ratio }) {
      // https://thanmtsvcb.slack.com/archives/DL97S0GUB/p1618814151010200
      return `
      <div class="font-bold flex">
        <span class="underline">
          Day:
        </span>
        <span class="ml-auto">
          &nbsp${ratio}%
        </span>
      </div>

      <div class="font-bold flex">
        <span class="underline">
          Long account:
        </span>
        <span class="ml-auto">
          &nbsp${curent}%
        </span>
      </div>`
    },
  },
}
</script>

<style lang="css" src="./style.css" scoped />
