const lxvx2Options = {
  chart: {
    height: 900,
  },
  legend: {
    enabled: true,
  },
  rangeSelector: {
    enabled: false,
  },
  title: {
    text: 'Indicative price bands from Bitvol and latest LXVX(purple)',
  },
  xAxis: {
    tickInterval: 24 * 3600 * 1000,
    showEmpty: true,
    gridLineWidth: 1,
    lineWidth: 0,
    tickWidth: 0,
    minPadding: 0,
    maxPadding: 0,
    min: Date.now() - 6 * 86400e3,
    type: 'datetime',
  },
  yAxis: [
    {
      tickInterval: 5,
      height: '20%',
      max: 0,
      min: Infinity,
      labels: {
        align: 'left',
        style: {
          color: '#75BDF7',
          fontSize: '13px',
        },
        formatter() {
          return this.value
        },
      },
    },
    {
      tickInterval: 2000,
      max: 0,
      min: Infinity,
      top: '20%',
      height: '90%',
      labels: {
        align: 'left',
        style: {
          color: '#FE9C58',
          fontSize: '13px',
        },
        x: -40,
        formatter() {
          return '$' + this.value / 1e3 + 'k'
        },
      },
    },
  ],
  plotOptions: {
    candlestick: {
      color: '#E74C3C',
      upColor: '#2ECD71',
    },
    line: {
      marker: {
        enabled: true,
        radius: 4,
        symbol: 'circle',
      },
    },
  },
  tooltip: {
    valueDecimals: 2,
    valuePrefix: '$',
    valueSuffix: ' USD',
  },
  series: [
    {
      name: 'Bitcoin Price',
      type: 'candlestick',
      data: [],
      yAxis: 1,
    },
    ...[
      {
        name: 'Bitvol High',
        color: '#C8C8C8',
        fillOpacity: 0.3,
      },
      {
        name: 'Bitvol Low',
        color: '#C8C8C8',
        fillOpacity: 0.3,
      },
      {
        name: 'Lxvx High',
        color: '#FF57D9',
        fillOpacity: 0.4,
      },
      {
        name: 'Lxvx Low',
        color: '#FF57D9',
        fillOpacity: 0.4,
      },
    ].map((item) => {
      return {
        ...item,
        type: 'arearange',
        data: [],
        yAxis: 1,
      }
    }),
    ...[
      { name: 'Bitvols', color: '#2899F3' },
      { name: 'Lxvx', color: '#FF57D9' },
    ].map(({ name, color }) => {
      return {
        name,
        data: [],
        yAxis: 0,
        color,
        tooltip: {
          valueDecimals: 2,
          valuePrefix: '',
          valueSuffix: '',
        },
      }
    }),
  ],
}

module.exports = { lxvx2Options }
