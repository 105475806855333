// const tsOfDay = 60 * 60 * 24 * 1000

const lxvxOptions = {
  chart: {
    height: 600,
  },
  legend: {
    enabled: true,
  },
  rangeSelector: {
    enabled: false,
  },
  title: {
    text: 'LedgerX Volatility Index',
  },
  xAxis: {
    min: Date.now() - 7 * 86400e3,
    type: 'datetime',
  },
  yAxis: [
    {
      height: '30%',
    },
    {
      top: '30%',
      height: '70%',
    },
  ],
  plotOptions: {
    candlestick: {
      color: '#E74C3C',
      upColor: '#2ECD71',
    },
  },
  tooltip: {
    valueDecimals: 2,
    valuePrefix: '$',
    valueSuffix: ' USD',
  },
  series: [
    {
      name: 'Bitcoin Price',
      type: 'candlestick',
      data: [],
      yAxis: 1,
    },
    ...[
      {
        name: 'Bitvol High',
        color: '#E1E1E1',
      },
      {
        name: 'Bitvol Low',
        color: '#E1E1E1',
      },
      {
        name: 'Lxvx High',
        color: '#E7B4E3',
      },
      {
        name: 'Lxvx Low',
        color: '#E7B4E3',
      },
    ].map((item) => {
      return {
        ...item,
        type: 'arearange',
        data: [],
        yAxis: 1,
      }
    }),
    ...['Bitvols', 'Lxvx'].map((name) => {
      return {
        name,
        data: [],
        yAxis: 0,
        tooltip: {
          valueDecimals: 2,
          valuePrefix: '',
          valueSuffix: '',
        },
      }
    }),
  ],
}

module.exports = { lxvxOptions }
