<template>
  <bar-card>
    <div class="flex flex-wrap justify-center">
      <router-link class="title md:text-xl" to="/markets"> PRICES </router-link>
    </div>
    <div class="bar-body my-1 sm:my-2 divide-y">
      <div class="bar-item h-24 justify-between" :class="premiumIndex.class">
        <div class="w-full">COINBASE/USDT</div>
        <div>{{ premiumIndex.val | fix2 | formatNumber }}</div>
      </div>
      <div class="bar-item h-24 justify-between" :class="bybitIndex.class">
        <div class="w-full">COINBASE/BYBIT</div>
        <div>{{ bybitIndex.val | fix2 | formatNumber }}</div>
      </div>
      <div :class="bybitPrices.class">
        <div class="bar-item justify-between h-1/2 px-1 py-3">
          <div class="">Price</div>
          <div class="text-right">
            {{ bybitPrices.last | formatNumber }}
          </div>
        </div>
        <div class="bar-item justify-between h-1/2 px-1 py-3">
          <div class="">Mark price</div>
          <div class="text-right">
            {{ bybitPrices.mark | formatNumber }}
          </div>
        </div>
        <div class="bar-item justify-between h-1/2 px-1 py-3">
          <div class="">Divergence</div>
          <div class="text-right">
            {{ (bybitPrices.last - bybitPrices.mark) | fix2 | formatNumber }}
          </div>
        </div>
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/markets/BarCard'
import { _calc, _class } from './index.js'

export default {
  name: 'ExchangesPrice',
  components: {
    BarCard,
  },
  props: {
    premium: {
      type: Object,
      required: true,
    },
    prices: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bybitPrices() {
      const diff = this.prices.last - this.prices.mark
      return {
        ...this.prices,
        diff,
        class: _class(-diff, { mid: 0, diff: 300 }),
      }
    },
    premiumIndex() {
      /*・COINBASE PREMIUM-INDEX
        対象：対象値
        上限：55：緑
        下限：-10：赤
      */
      return _calc(this.premium.usdt, { mid: 22.5, diff: 32.5 })
    },
    bybitIndex() {
      /*・COINBASE PREMIUM-INDEX
        対象：対象値
        上限：55：緑
        下限：-10：赤
      */
      return _calc(this.premium.bybit, { mid: 22.5, diff: 32.5 })
    },
  },
}
</script>

<style lang="css" src="./style.css" scoped />
