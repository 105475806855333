const list = [
  {
    name: 'USDT',
    color: '#de334f',
  },
  {
    name: 'BUSD',
    color: '#4751bc',
  },
  {
    name: 'PAX',
    color: '#7cbc47',
  },
  {
    name: 'USDC',
    color: '#e07809',
  },
  {
    name: 'HUSD',
    color: '#f0a313',
  },
]

export default {
  chart: {
    height: 800,
  },
  rangeSelector: {
    inputEnabled: false,
  },
  title: {
    text: 'Historical Data',
  },
  legend: {
    enabled: true,
  },
  xAxis: {
    type: 'datetime',
  },
  yAxis: [
    {
      labels: { align: 'right', x: -3 },
      height: '60%',
      lineWidth: 2,
      resize: { enabled: true },
    },
    {
      labels: {
        align: 'right',
        x: -3,
      },
      top: '60%',
      height: '10%',
      offset: 0,
      lineWidth: 2,
    },
    {
      plotLines: [
        {
          label: {
            align: 'center',
            text: 'Stable coin: 9000',
          },
          color: '#de334f',
          width: 2,
          value: 9000, // Need to set this probably as a var.
        },
      ],
      labels: {
        align: 'right',
        x: -3,
      },
      top: '70%',
      height: '25%',
      offset: 0,
      lineWidth: 2,
    },
    {
      plotLines: [
        {
          label: {
            align: 'center',
            text: 'WBTC: 500',
          },
          color: '#f09242',
          width: 2,
          value: 500, // Need to set this probably as a var.
        },
      ],
      title: { text: 'WBTC' },
      visible: true,
      opposite: false,
      top: '70%',
      height: '25%',
      offset: 0,
    },
    {
      title: { text: 'Grayscale' },
      visible: true,
      opposite: false,
      startOnTick: false,
      min: -1000,
      top: '70%',
      height: '25%',
      plotLines: [
        {
          label: {
            align: 'center',
            text: 'Grayscale: 900',
          },
          color: '#2bb498',
          width: 2,
          value: 900, // Need to set this probably as a var.
        },
      ],
    },
  ],
  plotOptions: { series: { stacking: 'normal' } },
  tooltip: { split: true },
  series: [
    {
      id: 'priceSeries',
      showInLegend: false,
      type: 'candlestick',
      name: 'Prices',
      color: '#bc4767',
      data: [],
    },
    {
      showInLegend: false,
      type: 'column',
      name: 'Volume',
      color: '#eddb7e',
      yAxis: 1,
      data: [],
    },
    {
      showInLegend: true,
      name: 'WBTC',
      color: '#f09242',
      yAxis: 3,
      type: 'column',
    },
    {
      showInLegend: true,
      name: 'Grayscale BTC',
      color: '#2bb498',
      yAxis: 4,
      type: 'column',
    },
  ]
    .concat(
      list.map(({ name, color }) => ({
        name,
        color,
        yAxis: 2,
        type: 'column',
        showInLegend: true,
        tooltip: { valueSuffix: ' ₿' },
      }))
    )
    .concat([
      {
        name: 'Liquidation',
        color: '#eb0520',
        type: 'flags',
        data: [],
        onSeries: 'priceSeries',
        shape: 'squarepin',
        width: 16,
      },
    ]),
}
