<template>
  <div
    class="flex flex-wrap text-base border-2 rounded shadow-sm flex-col mr-1 mt-1 p-1 w-40 rounded-xl sm:p-2 md:p-2 md:w-56 sm:mr-2 sm:mt-2"
  >
    <slot>
      <div class="flex flex-wrap">
        <a
          class="uppercase md:text-xl text-center pt-2"
          :href="link"
          target="_blank"
        >
          <span class="font-bold">{{ label }}</span>
          <template v-if="isMobile">
            <br />
            <span class="text-sm">
              {{ cur | formatNumber }}
            </span>
          </template>
        </a>
        <div
          v-if="prev"
          class="ml-auto"
          :class="isDown ? 'text-red-600' : 'text-green-700'"
        >
          <c-icon
            class="bg-white"
            v-if="isDown"
            name="trending-down"
            color="red"
            height="24"
            width="24"
          />
          <c-icon
            v-else
            class="bg-white"
            name="trending-up"
            height="24"
            width="24"
          />
          <span class="bg-white">{{ percentChange }}%</span>
        </div>
      </div>
      <div class="my-2 md:text-xl text-center font-light" v-if="!isMobile">
        {{ cur | formatNumber }}
      </div>
    </slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BarCard',
  props: {
    label: {
      type: String,
      required: false,
    },
    link: {
      type: String,
    },
    cur: {
      type: Number,
      require: false,
    },
    prev: {
      type: Number,
      require: false,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    isDown() {
      return this.prev > this.cur
    },
    percentChange() {
      if (this.prev) {
        return Math.round(10000 * (this.cur / this.prev - 1)) / 100
      }
      return 0
    },
  },
}
</script>
