var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('page-title',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('div',{staticClass:"flex justify-center items-center"},[_vm._v(" Market information "),_c('c-icon',{staticClass:"ml-3 cursor-pointer",attrs:{"name":_vm.display ? 'eye-off' : 'eye',"color":"gray","width":"28"},on:{"click":function($event){_vm.display = !_vm.display}}}),(_vm.point)?_c('span',{staticClass:"mx-4 font-normal"},[_vm._v("合計点数: "+_vm._s(_vm.point))]):_vm._e()],1),_c('div',{staticClass:"flex justify-end items-center text-sm space-x-4 w-full"},[_c('span',{staticClass:"space-x-2"},[_c('span',[_vm._v("現在の音声通知:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.notification))])]),_c('div',{staticClass:"flex items-center justify-end space-x-2"},[_c('span',[_vm._v("価格を音声通知")]),_c('c-select',{attrs:{"label":"","options":[
              {
                key: 60,
                text: '1分',
              },
              {
                key: 180,
                text: '3分',
              },
              {
                key: 300,
                text: '5分',
              } ],"value":_vm.priceNotificationSpan},on:{"input":function (value) { return _vm.$emit('on-update-price-notification-span', value); }}})],1)])])]),(_vm.display)?_c('market-bar'):_vm._e(),_c('div',{staticClass:"mt-8"}),_c('page-title',[_vm._v(" Exchange information ")]),_c('c-tabs',{ref:"marketTabs",staticClass:"mb-2",attrs:{"tabs":_vm.tabs},on:{"onChange":_vm.onTabChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}),_c('div',{staticClass:"wrapper justify-start md:justify-center"},[_c(_vm.component,{tag:"component"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }