<template>
  <bar-card>
    <div class="flex flex-wrap justify-center">
      <router-link class="title md:text-xl" to="/markets">
        FR ・ Pre FR
      </router-link>
    </div>
    <div class="bar-body my-1 sm:my-2 space-y-1">
      <div
        v-for="([label, rate, classes], idx) in funding"
        :key="idx"
        class="bar-item py-1"
        :class="classes"
      >
        <div class="w-full font-bold">{{ label }}</div>
        <div class="w-full text-right">{{ rate | percentage4 }}</div>
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/markets/BarCard'
import { _class } from './index.js'

export default {
  name: 'FundingRate',
  components: {
    BarCard,
  },
  props: {
    fundingData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    funding() {
      const funding = this.fundingData
      const exchanges = Object.keys(funding).filter((el) => el !== 'updated')
      const res = []

      const now = new Date().getTime()
      const span = 3600e3 * 8
      const base = new Date('2021/04/23 00:00:00 GMT+0').getTime()
      const diff = {
        BYBIT: 0,
        OKEX: 0,
        BINANCE: 0,
        FTX: -7 * 3600e3,
        BITMEX: 4 * 3600e3,
      }

      exchanges.forEach((exchange) => {
        const EXCHANGE = exchange.toUpperCase()
        const { rate, preRate } = funding[exchange]
        const mark = base + diff[EXCHANGE]
        const frTs = Math.ceil((now - mark) / span) * span + mark
        const preFrTs = frTs + span
        const frHour = new Date(frTs).getHours()
        const preFrHour = new Date(preFrTs).getHours()
        res.push([
          `${EXCHANGE} FR (${frHour}:00)`,
          rate,
          _class(-rate, { mid: -0.0001, diff: 0.0009 }),
        ])
        if (typeof preRate === 'number') {
          res.push([
            `${EXCHANGE} Pre FR (${preFrHour}:00)`,
            preRate,
            _class(-preRate, { mid: -0.0001, diff: 0.0009 }),
          ])
        }
      })
      return res
    },
  },
}
</script>

<style lang="css" src="./style.css" scoped />
