<template>
  <div class="flex w-full flex-col pb-64">
    <div class="border border-lg">
      <chart
        v-if="ready"
        :options="xchartOptions"
        constructor-type="stockChart"
        ref="xChart"
      />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import cloneDeep from 'lodash.clonedeep'
import { xchartOptions } from './_xchartOptions'

const LENGTH = 33
const [
  TIMESTAMP_IDX,

  BITMEX_XBTUSD_PRICE_IDX,
  BINANCEF_BTCUSDT_PRICE_IDX,
  BYBIT_BTCUSD_PRICE_IDX,
  FTX_BTCPREP_PRICE_IDX,
  OKEX_BTCUSD_PRICE_IDX,
  HUOBI_BTCUSD_PRICE_IDX,
  BITMEX_ETHUSD_PRICE_IDX,
  BINANCEF_ETHUSDT_PRICE_IDX,

  BITMEX_XBTUSD_OI_IDX,
  BINANCEF_BTCUSDT_OI_IDX,
  BYBIT_BTCUSD_OI_IDX,
  FTX_BTCPREP_OI_IDX,
  OKEX_BTCUSD_OI_IDX,
  HUOBI_BTCUSD_OI_IDX,
  BITMEX_ETHUSD_OI_IDX,
  BINANCEF_ETHUSDT_OI_IDX,

  BITMEX_XBTUSD_BASIC_IDX,
  BINANCEF_BTCUSDT_BASIC_IDX,
  BYBIT_BTCUSD_BASIC_IDX,
  FTX_BTCPREP_BASIC_IDX,
  OKEX_BTCUSD_BASIC_IDX,
  HUOBI_BTCUSD_BASIC_IDX,
  BITMEX_ETHUSD_BASIC_IDX,
  BINANCEF_ETHUSDT_BASIC_IDX,

  BITMEX_XBTUSD_FUNDING_RATE_IDX,
  BINANCEF_BTCUSDT_FUNDING_RATE_IDX,
  BYBIT_BTCUSD_FUNDING_RATE_IDX,
  FTX_BTCPREP_FUNDING_RATE_IDX,
  OKEX_BTCUSD_FUNDING_RATE_IDX,
  HUOBI_BTCUSD_FUNDING_RATE_IDX,
  BITMEX_ETHUSD_FUNDING_RATE_IDX,
  BINANCEF_ETHUSDT_FUNDING_RATE_IDX,
] = [...Array(LENGTH)].map((_, idx) => idx)

const constants = {
  TIMESTAMP_IDX,

  BITMEX_XBTUSD_PRICE_IDX,
  BINANCEF_BTCUSDT_PRICE_IDX,
  BYBIT_BTCUSD_PRICE_IDX,
  FTX_BTCPREP_PRICE_IDX,
  OKEX_BTCUSD_PRICE_IDX,
  HUOBI_BTCUSD_PRICE_IDX,
  BITMEX_ETHUSD_PRICE_IDX,
  BINANCEF_ETHUSDT_PRICE_IDX,

  BITMEX_XBTUSD_OI_IDX,
  BINANCEF_BTCUSDT_OI_IDX,
  BYBIT_BTCUSD_OI_IDX,
  FTX_BTCPREP_OI_IDX,
  OKEX_BTCUSD_OI_IDX,
  HUOBI_BTCUSD_OI_IDX,
  BITMEX_ETHUSD_OI_IDX,
  BINANCEF_ETHUSDT_OI_IDX,

  BITMEX_XBTUSD_BASIC_IDX,
  BINANCEF_BTCUSDT_BASIC_IDX,
  BYBIT_BTCUSD_BASIC_IDX,
  FTX_BTCPREP_BASIC_IDX,
  OKEX_BTCUSD_BASIC_IDX,
  HUOBI_BTCUSD_BASIC_IDX,
  BITMEX_ETHUSD_BASIC_IDX,
  BINANCEF_ETHUSDT_BASIC_IDX,

  BITMEX_XBTUSD_FUNDING_RATE_IDX,
  BINANCEF_BTCUSDT_FUNDING_RATE_IDX,
  BYBIT_BTCUSD_FUNDING_RATE_IDX,
  FTX_BTCPREP_FUNDING_RATE_IDX,
  OKEX_BTCUSD_FUNDING_RATE_IDX,
  HUOBI_BTCUSD_FUNDING_RATE_IDX,
  BITMEX_ETHUSD_FUNDING_RATE_IDX,
  BINANCEF_ETHUSDT_FUNDING_RATE_IDX,
}

function _round(val, digit = 6) {
  const k = 10 ** digit
  return Math.round(val * k) / k
}

const ONE_DAY = 24 * 3600e3
const BTC_PRICE_AXIS_INDEX = 0
const ETH_PRICE_AXIS_INDEX = 1
const BTC_OI_AXIS_INDEX = 2
const ETH_OI_AXIS_INDEX = 3
const BTC_ACTIVE_OI_AXIS_INDEX = 4
const ETH_ACTIVE_OI_AXIS_INDEX = 5
const BASIC_INDEX = 6
const FUNDING_RATE_INDEX = 7

const COLORS = {
  DARK: {
    HUOBI_BTCUSD: '#9bc54a',
    OKEX_BTCUSD: '#eaba4c',
    FTX_BTCPREP: '#acbcec',
    BYBIT_BTCUSD: '#c47fcf',
    BINANCEF_BTCUSDT: '#d09855',
    BITMEX_XBTUSD: '#cf5664',
    BITMEX_ETHUSD: '#547ec0',
    BINANCEF_ETHUSDT: '#58af59',
  },
  LIGHT: {
    HUOBI_BTCUSD: '#bcd884',
    OKEX_BTCUSD: '#f0d084',
    FTX_BTCPREP: '#82d2f2',
    BYBIT_BTCUSD: '#d1a2d9',
    BINANCEF_BTCUSDT: '#deb88b',
    BITMEX_XBTUSD: '#de8e97',
    BITMEX_ETHUSD: '#8ca8d4',
    BINANCEF_ETHUSDT: '#8ec98f',
  },
}

const EXCHANGE_PAIRS = [
  'BINANCEF_ETHUSDT',
  'BITMEX_ETHUSD',
  'HUOBI_BTCUSD',
  'OKEX_BTCUSD',
  'FTX_BTCPREP',
  'BYBIT_BTCUSD',
  'BINANCEF_BTCUSDT',
  'BITMEX_XBTUSD',
]

function _isETH(pair) {
  return pair.includes('ETH')
}
function _series() {
  const series = {}

  EXCHANGE_PAIRS.forEach((prefix) => {
    const isETH = _isETH(prefix)

    let key = `${prefix}_PRICE_IDX`
    series[key] = {
      name: `${prefix}`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' $',
      },
      yAxis: isETH ? ETH_PRICE_AXIS_INDEX : BTC_PRICE_AXIS_INDEX,
      data: [],
    }

    key = `${prefix}_OI_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - OI`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' $',
      },
      yAxis: isETH ? ETH_OI_AXIS_INDEX : BTC_OI_AXIS_INDEX,
      data: [],
      zones: [],
    }

    key = `${prefix}_ACTIVE_OI_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - Active OI`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' $',
      },
      yAxis: isETH ? ETH_ACTIVE_OI_AXIS_INDEX : BTC_ACTIVE_OI_AXIS_INDEX,
      data: [],
      zones: [],
    }

    key = `${prefix}_BASIC_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - Basic`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' %',
      },
      yAxis: BASIC_INDEX,
      data: [],
    }
    key = `${prefix}_FUNDING_RATE_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - Funding`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: ' %',
      },
      yAxis: FUNDING_RATE_INDEX,
      data: [],
    }
  })

  return series
}
export default {
  name: 'XCharts',
  components: {
    Chart,
  },
  data: () => ({
    ready: false,
    isBand: false,
    xchartOptions: {},
  }),
  methods: {
    async fetch() {
      this.ready = false
      const options = cloneDeep(xchartOptions)
      const data = await this.$http.get('/api/v1/markets/x-chart')
      const arr = Object.values(data)
      arr.sort((a, b) => a[TIMESTAMP_IDX] - b[TIMESTAMP_IDX])

      let btcMin = 1e9,
        btcMax = 0,
        ethMin = 1e9,
        ethMax = 0

      const series = _series()
      let blank = true
      const from = Date.now() - 4 * ONE_DAY
      arr.forEach((el) => {
        const timestamp = el[constants.TIMESTAMP_IDX]
        if (timestamp < from) {
          return
        }

        EXCHANGE_PAIRS.forEach((prefix) => {
          // Price
          let key = `${prefix}_PRICE_IDX`
          const price = el[constants[key]]
          if (price > 1) {
            series[key].data.push([timestamp, price])
            if (_isETH(prefix)) {
              ethMin = Math.min(price, ethMin)
              ethMax = Math.max(price, ethMax)
            } else {
              btcMin = Math.min(price, btcMin)
              btcMax = Math.max(price, btcMax)
            }
          }

          // OI
          key = `${prefix}_OI_IDX`
          const oi = el[constants[key]]
          if (oi > 100) {
            series[key].data.push([timestamp, parseInt(oi)])
          }

          // Active OI
          const ts = timestamp - 1 * ONE_DAY
          const prevTimestamp = ts
          const prev = data[prevTimestamp] || {}
          const prevOi = prev[constants[key]] || 0
          if (prevOi > 1000) {
            const activeOi = Math.round(oi - prevOi)
            const sign = Math.sign(activeOi)
            const abs = Math.abs(activeOi)
            const { data, zones } = series[`${prefix}_ACTIVE_OI_IDX`]
            data.push([timestamp, abs])
            if (!blank) {
              zones.push({
                value: timestamp,
                color: sign < 0 ? COLORS.DARK[prefix] : COLORS.LIGHT[prefix],
              })
            }
          }

          // Basic
          key = `${prefix}_BASIC_IDX`
          const basic = _round(100 * el[constants[key]], 2)
          series[key].data.push([timestamp, basic])

          // Funding
          key = `${prefix}_FUNDING_RATE_IDX`
          const rate = _round(100 * el[constants[key]], 2)
          series[key].data.push([timestamp, rate])
        })
      })

      options.series = Object.values(series)

      options.yAxis[BTC_PRICE_AXIS_INDEX].min = Math.floor(btcMin / 100) * 100
      options.yAxis[BTC_PRICE_AXIS_INDEX].max = Math.ceil(btcMax / 100) * 100
      options.yAxis[ETH_PRICE_AXIS_INDEX].min = Math.floor(ethMin / 10) * 10
      options.yAxis[ETH_PRICE_AXIS_INDEX].max = Math.ceil(ethMax / 10) * 10
      this.xchartOptions = options
      this.ready = true
    },
  },
  mounted() {
    this.fetch()
  },
}
</script>
