import { Chart } from 'highcharts-vue'

export default {
  components: {
    Chart,
  },
  data: () => ({
    ready: false,
    options: {},
  }),
  mounted() {
    this.ready = false
    this.fetch().then(() => {
      this.ready = true
    })
  },
}
