export default {
  chart: { height: 600 },
  legend: {
    enabled: true,
  },
  rangeSelector: { inputEnabled: false },
  title: { text: 'ETF-BTCC' },
  subtitle: {
    text:
      'Source: <a href="https://money.tmx.com/en/quote/BTCC.U">TMX Money</a>',
  },
  xAxis: {
    type: 'datetime',
  },
  yAxis: [
    {
      visible: true,
      opposite: false,
      title: { text: '' },
    },
  ],
  series: [
    {
      name: 'BTCC.B',
      color: '#244eb8',
      data: [],
    },
    {
      name: 'BTCC.B.APH',
      color: '#2bb498',
      data: [],
    },
    {
      name: 'BTCC.U',
      color: '#dd2860',
      data: [],
    },
  ],
}
