<template>
  <div class="w-full">
    <c-raw-table :headers="tableHeaders" :items="tweets" height="780px">
      <template v-slot:header="{ headers }">
        <tr>
          <th
            v-for="(item, key) in headers"
            :key="key"
            class="raw-table-header"
          >
            {{ item }}
          </th>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <td class="raw-table-item">
          {{ new Date(item.created_at).getTime() | date }}
        </td>
        <td class="raw-table-item font-bold">
          <a :href="url(item)" target="_blank">
            {{ username(item) }}
          </a>
        </td>
        <td class="raw-table-item">
          <span v-html="content(item.text)" />
        </td>
        <td class="raw-table-item">
          {{ item.price || '-' | formatNumber }}
        </td>
      </template>
    </c-raw-table>
  </div>
</template>

<script>
const headers = ['date', 'twitter', 'content', 'price']

export default {
  name: 'Twitter',
  data() {
    return {
      tableHeaders: headers,
      tweets: [],
    }
  },
  created() {
    this.$http.get('/api/market/tweets').then((result) => {
      this.tweets = result
      this.tweets.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      })
    })
  },
  methods: {
    url(item) {
      if (item.link) {
        return item.link
      }

      return item.url
        ? item.url
        : `https://twitter.com/${item.channel}/status/${item.id_str}`
    },
    content(text) {
      const map = { ja: [50, 40], en: [120, 100] }
      const lang = text.match(/[\u3400-\u9FBF]/) ? 'ja' : 'en'
      const [maxSplit, idealSplit] = map[lang]
      const lines = ['']
      let lineCounter = 0,
        lineIndex = 0,
        ch,
        i
      for (i = 0; i < text.length; i++) {
        ch = text[i]
        if (
          (lineCounter >= idealSplit && ch === ' ') ||
          lineCounter >= maxSplit
        ) {
          ch = ''
          lineCounter = -1
          lineIndex++
          lines.push('')
        }
        lines[lineIndex] += ch
        lineCounter++
      }
      return lines.join('<br>')
    },
    username(item) {
      if (item.link) {
        return new URL(item.link).host
      }
      return item.user.name
    },
  },
}
</script>
