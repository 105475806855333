const height = [15, 15, 10, 20, 15, 15, 10]
const top = [0, 15, 30, 40, 60, 75, 90]
const xchartOptions = {
  series: [],
  chart: {
    // height: Math.round(window.innerWidth / 14 * 15),
    height: 800,
  },
  tooltip: {
    split: false,
    shared: false,
    formatter: function () {
      let str = '<b>' + _date(this.x) + '</b><br/>'
      const {
        index,
        series: {
          yAxis: { series },
        },
      } = this.point
      series.forEach((series) => {
        const { name, color, processedYData } = series
        str += _tooltip(color, name, _format(processedYData[index]))
      })
      return str
    },
  },
  rangeSelector: {
    enabled: false,
  },
  title: {
    text: 'XCharts',
  },
  xAxis: {
    type: 'datetime',
    scrollbar: {
      enabled: true,
    },
    plotBands: [],
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        width: 0,
        radius: 0,
      },
      lineWidth: 1,
      // zoneAxis: 'x',
    },
  },
  yAxis: [
    {
      // 'BTC/USD',
      labels: {
        style: {
          color: '#cf5664',
          fontSize: '9px',
        },
        formatter() {
          return '$' + this.value
        },
      },
      opposite: true,
      gridLineWidth: 0,
      height: height[0] + '%',
      offset: 0,
      // title: {
      //   text: 'BTCUSD',
      //   margin: 10,
      // },
    },
    {
      // 'ETH/USD',
      labels: {
        align: 'left',
        style: {
          color: '#547ec0',
          fontSize: '9px',
        },
        formatter() {
          return '$' + this.value
        },
      },
      opposite: false,
      gridLineWidth: 0,
      height: height[0] + '%',
      offset: 0,
      // title: {
      //   text: 'ETHUSD',
      // },
    },
    {
      // 'BTC OI',
      labels: {
        style: {
          color: '#cf5664',
          fontSize: '9px',
        },
        formatter() {
          return this.value > 1e9
            ? Math.round(this.value / 1e9) + 'B'
            : Math.round(this.value / 1e6) + 'M'
        },
      },
      opposite: true,
      gridLineWidth: 0,
      height: height[1] + '%',
      top: top[1] + '%',
      // title: {
      //   text: 'BTC OI',
      // },
    },
    {
      // 'ETH OI',
      labels: {
        style: {
          color: '#cf5664',
          fontSize: '9px',
        },
        formatter() {
          return this.value > 1e9
            ? Math.round(this.value / 1e9) + 'B'
            : Math.round(this.value / 1e6) + 'M'
        },
      },
      opposite: true,
      gridLineWidth: 0,
      height: height[2] + '%',
      top: top[2] + '%',
      // title: {
      //   text: 'ETH OI',
      //   margin: 10,
      // },
    },
    {
      // 'BTC Active OI',
      labels: {
        style: {
          color: '#cf5664',
          fontSize: '9px',
        },
        formatter() {
          return this.value > 1e9
            ? Math.round(this.value / 1e9) + 'B'
            : Math.round(this.value / 1e6) + 'M'
        },
      },
      opposite: true,
      gridLineWidth: 0,
      height: height[3] + '%',
      top: top[3] + '%',
      // title: {
      //   text: 'Active BTC OI',
      // },
    },
    {
      // 'ETH Active OI',
      labels: {
        style: {
          color: '#cf5664',
          fontSize: '9px',
        },
        formatter() {
          return this.value > 1e9
            ? Math.round(this.value / 1e9) + 'B'
            : Math.round(this.value / 1e6) + 'M'
        },
      },
      opposite: true,
      gridLineWidth: 0,
      height: height[4] + '%',
      top: top[4] + '%',
      // title: {
      //   text: 'Active ETH OI',
      // },
    },
    {
      // 'Basic',
      labels: {
        style: {
          color: 'blue',
          fontSize: '9px',
        },
        formatter() {
          return this.value + ' %'
        },
      },
      opposite: true,
      gridLineWidth: 0,
      height: height[5] + '%',
      top: top[5] + '%',
      // title: {
      //   text: 'Basic',
      // },
    },
    {
      // 'Funding',
      labels: {
        style: {
          color: '#cf5664',
          fontSize: '9px',
        },
        formatter() {
          return this.value + ' %'
        },
      },
      opposite: true,
      gridLineWidth: 0,
      height: height[6] + '%',
      top: top[6] + '%',
      // title: {
      //   text: 'Funding Rate',
      // },
    },
  ],
}

function _round(val, digit = 0) {
  const k = 10 ** digit
  return Math.round(val * k) / k
}

function _format(value) {
  if (value < 1) {
    return value + ' %'
  }

  if (value > 1e5) {
    value = _round(value, 0)
  }

  const arr = value.toString().split('.')
  const str =
    arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (arr[1] ? '.' + arr[1] : '')
  return '$' + str
}

function _tooltip(color, name, value) {
  return (
    `<span style="color: ${color};">` +
    `${name}: <span>${value}</span></span><br>`
  )
}

function _date(val) {
  function _2digit(val) {
    return val < 10 ? '0' + val : val
  }

  const _date = new Date(val)
  const year = _date.getFullYear()
  const month = _2digit(_date.getMonth() + 1)
  const date = _date.getDate()
  let hour = _2digit(_date.getHours())
  let minute = _2digit(_date.getMinutes())
  return `${year}/${month}/${date} ${hour}:${minute}`
}

export { xchartOptions }
