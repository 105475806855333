var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bar-card',[_c('div',{staticClass:"flex flex-wrap justify-center"},[_c('router-link',{staticClass:"title md:text-xl",attrs:{"to":"/markets"}},[_vm._v(" CRYPTO QUANT ")])],1),_c('div',{staticClass:"bar-body my-0.5 sm:my-2 space-y-2"},_vm._l((_vm.group),function(ref){
var label = ref.label;
var list = ref.list;
var classes = ref.classes;
return _c('div',{key:label,staticClass:"bar-item flex flex-col",class:classes},[_c('div',{staticClass:"w-full flex justify-center font-bold underline"},[_vm._v(" "+_vm._s(label)+" ")]),_c('div',{staticClass:"w-full flex flex-col space-y-0.5"},_vm._l((list),function(ref,idx){
var label = ref.label;
var val = ref.val;
return _c('div',{key:idx,staticClass:"bar-item-band"},[_c('span',[_vm._v(_vm._s(label))]),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(val)))])])}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }