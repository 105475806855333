function _round(val, digit = 0) {
  const k = 10 ** digit
  return Math.round(val * k) / k
}

function _class(val, { mid, diff }) {
  const up = {
    0: 'bg-green-200',
    1: 'bg-green-300',
    2: 'bg-green-400',
    3: 'bg-green-500',
    4: 'bg-green-600',
    5: 'bg-green-700',
  }
  const down = {
    0: 'bg-red-200',
    1: 'bg-red-300',
    2: 'bg-red-400',
    3: 'bg-red-500',
    4: 'bg-red-600',
    5: 'bg-red-700',
  }
  const k = Math.min(Math.round(Math.abs((val - mid) / diff) * 5), 5)
  return val > mid ? up[k] : val < mid ? down[k] : ''
}

function _calc(val, { mid, diff }) {
  return {
    val: _round(val, 6),
    class: _class(val, { mid, diff }),
  }
}

function _rate(now, last, { mid, diff }, reverse = false) {
  const rate = now / last
  const k = reverse ? -1 : 1
  return { now, last, ..._calc(k * rate, { mid: k * mid, diff }) }
}

function _bCacl(obj) {
  /*・BINANCE STATS
        Top Trader (Accounts)
          対象：下段数値
          上限：+100%：赤
          下限：-100%：緑
        Top Trader (Position)
          対象：下段数値
          上限：+100%：赤
          下限：-100%：緑
        Grobal Trader
          対象：下段数値
          上限：+100% ：赤
          下限：-100%：緑
  */

  if (!obj) {
    return {}
  }

  return {
    ..._calc(-obj.rate, { mid: -0.5, diff: 0.5 }),
    curent: _round(100 * obj.curent, 2),
    ratio: _round(100 * obj.rate, 2),
  }
}

export { _class, _round, _calc, _rate, _bCacl }
