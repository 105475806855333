<template>
  <div class="flex w-full flex-col">
    <divider />
    <div class="border border-lg bg-gray-900 shadow-lg">
      <chart v-if="ready" :options="chartOptions" />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import chartOptions from './_etfBtccOptions'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'Etf-btcc',
  components: {
    Chart,
  },
  data() {
    return {
      ready: false,
      chartOptions: null,
      timer: null,
    }
  },
  async created() {
    await this.initChart()
    this.ready = true
  },
  methods: {
    async initChart() {
      this.chartOptions = cloneDeep(chartOptions)
      const data = await this.$http.get('/api/market/etf-btcc')
      data.forEach((item) => {
        const { timestamp } = item
        const B = item['BTCC.B']
        const U = item['BTCC.U']
        const APH = item['BTCC.B:APH']

        this.chartOptions.series[0].data.push([timestamp, B])
        this.chartOptions.series[1].data.push([timestamp, U])
        this.chartOptions.series[2].data.push([timestamp, APH])
      })
      this.timer = setTimeout(this.initChart, 60e3)
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
}
</script>
