<template>
  <bar-card>
    <div class="flex flex-wrap justify-center">
      <router-link class="title md:text-xl" to="/markets">
        Active OI ・ BASIC
      </router-link>
    </div>
    <div class="bar-body my-0.5 sm:my-2 divide-y space-y-2">
      <div class="bar-item flex flex-col">
        <div class="w-full flex justify-center font-bold underline">BTC</div>
        <div
          class="w-full flex flex-col space-y-0.5"
          :class="btcActiveOI.class"
        >
          <div class="bar-item-band px-1">
            <span>OI (USD) </span>
            <span> {{ xChart.OI.btc | formatNumber }} </span>
          </div>
          <div class="bar-item-band px-1">
            <span>OI (BTC) </span>
            <span>
              {{ (xChart.OI.btc / btcusd) | noDecimal | formatNumber }}
            </span>
          </div>
          <div class="bar-item-band px-1">
            <span>ActiveOI</span>
            <span> {{ btcActiveOI.val | formatNumber }} </span>
          </div>
          <div class="bar-item-band px-1">
            <span>ActiveOI(BTC):</span>
            <span>
              {{ (btcActiveOI.val / btcusd) | noDecimal | formatNumber }}
            </span>
          </div>
          <div class="bar-item-band px-1">
            <span>Change:</span>
            <span> {{ btcActiveOI.change | formatNumber }} </span>
          </div>
          <div class="bar-item-band px-1">
            <span>Change(BTC):</span>
            <span>
              {{ (btcActiveOI.change / btcusd) | noDecimal | formatNumber }}
            </span>
          </div>
          <div class="bar-item-band px-1" :class="btcBasic.class">
            <span>Basic:</span>
            <span>
              {{ btcBasic.val | percentage3 }}
            </span>
          </div>
        </div>
      </div>
      <div class="bar-item flex flex-col">
        <div class="w-full flex justify-center font-bold underline">ETH</div>
        <div
          class="w-full flex flex-col space-y-0.5"
          :class="ethActiveOI.class"
        >
          <div class="bar-item-band px-1">
            <span>OI (USD) </span>
            <span> {{ xChart.OI.eth | formatNumber }} </span>
          </div>
          <div class="bar-item-band px-1">
            <span>OI (ETH) </span>
            <span>
              {{ (xChart.OI.eth / ethusd) | noDecimal | formatNumber }}
            </span>
          </div>
          <div class="bar-item-band px-1">
            <span>ActiveOI</span>
            <span> {{ ethActiveOI.val | formatNumber }} </span>
          </div>
          <div class="bar-item-band px-1">
            <span>ActiveOI(ETH):</span>
            <span>
              {{ (ethActiveOI.val / ethusd) | noDecimal | formatNumber }}
            </span>
          </div>
          <div class="bar-item-band px-1">
            <span>Change:</span>
            <span> {{ ethActiveOI.change | formatNumber }} </span>
          </div>
          <div class="bar-item-band px-1">
            <span>Change(ETH):</span>
            <span>
              {{ (ethActiveOI.change / ethusd) | noDecimal | formatNumber }}
            </span>
          </div>
          <div class="bar-item-band px-1" :class="btcBasic.class">
            <span>Basic:</span>
            <span>
              {{ ethBasic.val | percentage3 }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/markets/BarCard'
import { _class, _calc } from './index.js'

export default {
  name: 'OI',
  components: {
    BarCard,
  },
  props: {
    xChart: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ethusd() {
      return this.xChart.price.ethusd
    },
    btcusd() {
      return this.xChart.price.btcusd
    },
    btcActiveOI() {
      const now = this.xChart.activeOI.now.btc
      const prev = this.xChart.activeOI.prev.btc
      const change = now - prev
      const rate = change / this.xChart.OI.btc
      return {
        rate,
        change,
        val: Math.abs(now),
        class: _class(-rate, { mid: 0, diff: 0.2 }),
      }
    },
    ethActiveOI() {
      const now = this.xChart.activeOI.now.eth
      const prev = this.xChart.activeOI.prev.eth
      const change = now - prev
      const rate = change / this.xChart.OI.eth
      return {
        rate,
        change,
        val: Math.abs(now),
        class: _class(-rate, { mid: 0, diff: 0.2 }),
      }
    },
    ethBasic() {
      /*・BTC,ETH 各合計Basis
        対象：数値
        上限：0.05%：赤
        下限：-0.06% ：緑
      */
      const val = this.xChart.basic.eth
      return {
        ..._calc(-val, {
          mid: 0.005,
          diff: 0.055,
        }),
        val,
      }
    },
    btcBasic() {
      /*・BTC,ETH 各合計Basis
        対象：数値
        上限：0.05%：赤
        下限：-0.06% ：緑
      */
      const val = this.xChart.basic.btc
      return {
        ..._calc(-val, {
          mid: 0.005,
          diff: 0.055,
        }),
        val,
      }
    },
  },
}
</script>

<style lang="css" src="./style.css" scoped />
