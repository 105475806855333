<template>
  <div class="w-full flex flex-col">
    <c-tabs :tabs="tabs" v-model="tab" class="mb-4" />

    <template v-if="tab.key === tabKeys.STABLE_COIN">
      <page-sub-title> Stable coins</page-sub-title>
      <div class="overflow-y-auto">
        <c-raw-table :headers="stableCoinHeaders" :items="mints" height="380px">
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold">
              {{ item.symbol.toUpperCase() }}
            </td>

            <td class="raw-table-item">
              {{ item.amount | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.price | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.timestamp || '-' | date }}
            </td>
          </template>
        </c-raw-table>
      </div>
    </template>

    <template v-if="tab.key === tabKeys.TRANSFER">
      <page-sub-title class="mt-4"> Transfers</page-sub-title>
      <div class="overflow-y-auto">
        <c-raw-table
          :headers="transfersHeaders"
          :items="transfers"
          height="390px"
        >
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold uppercase">
              {{ item.from.owner || 'UNKNOWN' }}
            </td>

            <td class="raw-table-item uppercase">
              {{ item.to.owner || 'UNKNOWN' }}
            </td>

            <td class="raw-table-item uppercase">
              {{ item.amount | formatNumber }} ({{ item.symbol }})
            </td>

            <td class="raw-table-item">
              {{ item.price || '-' | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ (item.timestamp * 1e3) | date }}
            </td>
          </template>
        </c-raw-table>
      </div>
    </template>

    <template v-if="tab.key === tabKeys.LIQUIDATION">
      <page-sub-title> Liquidation</page-sub-title>
      <div class="overflow-y-auto w-full">
        <c-raw-table
          :headers="liquidationHeaders"
          :items="liquidation"
          height="800px"
        >
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold uppercase">
              {{ item.exchange }}
            </td>

            <td class="raw-table-item">
              {{ item.volume | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.side }}
            </td>

            <td class="raw-table-item">
              {{ item.price | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.timestamp || '-' | date }}
            </td>
          </template>
        </c-raw-table>
      </div>
    </template>
  </div>
</template>

<script>
import {
  transfersHeaders,
  stableCoinHeaders,
  liquidationHeaders,
} from './_collectionsComponentCommon'
import createdMixin from '../../mixins/colection'

const TRANSFER = 'TRANSFER'
const STABLE_COIN = 'STABLE_COIN'
const LIQUIDATION = 'LIQUIDATION'
const tabs = [
  {
    title: 'Stable Coin',
    key: STABLE_COIN,
  },
  {
    title: 'Liquidation',
    key: LIQUIDATION,
  },
  {
    title: 'Transfer',
    key: TRANSFER,
  },
]

export default {
  name: 'CollectionsMobile',
  mixins: [createdMixin],
  data() {
    return {
      mints: [],
      transfers: [],
      liquidation: [],
      transfersHeaders,
      stableCoinHeaders,
      liquidationHeaders,
      tab: tabs[2],
      tabs,
      tabKeys: {
        TRANSFER,
        STABLE_COIN,
        LIQUIDATION,
      },
    }
  },
}
</script>
