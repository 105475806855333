<template>
  <div class="flex w-full flex-col pb-64">
    <div class="border border-lg">
      <chart
        v-if="ready"
        :options="options"
        constructor-type="stockChart"
        ref="buy-sell"
      />
    </div>
  </div>
</template>

<script>
import ChartMixin from '../../mixins/chart'

const serieOption = {
  visible: true,
  type: 'area',
  fillOpacity: 0,
  lineWidth: 1,
  step: 'center',
}

const heights = [16, 14, 14, 14, 14, 14, 10]
let top = 0
const tops = heights.map((el) => {
  top += el
  return top - el
})

const yAxis = [...Array(7)].map((el, idx) => {
  return {
    visible: true,
    opposite: false,
    top: tops[idx] + '%',
    height: heights[idx] + '%',
    labels: {
      x: 50,
      align: 'right',
      enabled: false,
    },
  }
})

yAxis[0].labels.x = 0

const options = {
  yAxis,
  xAxis: { type: 'datetime' },
  chart: { height: 1200 },
  legend: { enabled: true },
  rangeSelector: { inputEnabled: false },
  title: { text: 'Bitcoin - Buy vs Sell' },
  plotOptions: {},
  series: [
    {
      visible: true,
      color: '#ff9900',
      tooltip: { valuePrefix: '$ ' },
      name: 'Price',
      data: [],
      yAxis: 0,
    },
    {
      ...serieOption,
      yAxis: 1,
      data: [],
      name: 'Spot Buy',
      color: '#047017',
    },
    {
      ...serieOption,
      yAxis: 1,
      data: [],
      name: 'Spot Sell',
      color: '#cc1010',
    },
    {
      ...serieOption,
      yAxis: 2,
      data: [],
      name: 'Derivs Buy',
      color: '#047017',
    },
    {
      ...serieOption,
      yAxis: 2,
      data: [],
      name: 'Derivs Sell',
      color: '#cc1010',
    },
    {
      ...serieOption,
      yAxis: 3,
      data: [],
      name: 'USDT Buy',
      color: '#047017',
    },
    {
      ...serieOption,
      yAxis: 3,
      data: [],
      name: 'USDT Sell',
      color: '#cc1010',
    },
    {
      visible: true,
      type: 'column',
      yAxis: 4,
      data: [],
      tooltip: { valueSuffix: '%' },
      name: 'Spot Buy / Sell ratio',
      color: '#047017',
    },
    {
      visible: true,
      type: 'column',
      yAxis: 4,
      data: [],
      tooltip: { valueSuffix: '%' },
      name: 'Spot Buy / Sell ratio',
      color: '#cc1010',
    },
    {
      visible: true,
      type: 'column',
      yAxis: 5,
      data: [],
      tooltip: { valueSuffix: '%' },
      name: 'Derivs Buy / Sell ratio',
      color: '#047017',
    },
    {
      visible: true,
      type: 'column',
      yAxis: 5,
      data: [],
      tooltip: { valueSuffix: '%' },
      name: 'Derivs Buy / Sell ratio',
      color: '#cc1010',
    },
    {
      visible: true,
      type: 'column',
      yAxis: 6,
      data: [],
      tooltip: { valueSuffix: '%' },
      name: 'USDT Buy / Sell ratio',
      color: '#047017',
    },
    {
      visible: true,
      type: 'column',
      yAxis: 6,
      data: [],
      tooltip: { valueSuffix: '%' },
      name: 'USDT Buy / Sell ratio',
      color: '#cc1010',
    },
  ],
}

function _rate(b, s) {
  return Math.round((10000 * b) / (b + s)) / 100
}

const ONE_DAY = 86400000

export default {
  name: 'BuySell',
  mixins: [ChartMixin],
  methods: {
    async fetch() {
      const data = await this.$http.get('/api/v1/markets/discord/buy-sell')
      const limit = Date.now() - ONE_DAY / 2
      Object.keys(data)
        .filter((timestamp) => parseInt(timestamp) > limit)
        .sort()
        .map((timestamp) => {
          const obj = data[timestamp]
          const ts = parseInt(timestamp)
          const [price, sb, ss, db, ds, ub, us] = obj

          // Price
          options.series[0].data.push([ts, price])

          // // Spot Buy / Sell
          options.series[1].data.push([ts, sb])
          options.series[2].data.push([ts, ss])

          // // Derivs Buy / Sell
          options.series[3].data.push([ts, db])
          options.series[4].data.push([ts, ds])

          // // USDT Buy / Sell
          options.series[5].data.push([ts, ub])
          options.series[6].data.push([ts, us])

          // Spot Buy / Sell ratio
          if (sb > ss) {
            options.series[7].data.push([ts, _rate(sb, ss)])
          } else {
            options.series[8].data.push([ts, -_rate(ss, sb)])
          }

          // Derivs Buy / Sell ratio
          if (db > ds) {
            options.series[9].data.push([ts, _rate(db, ds)])
          } else {
            options.series[10].data.push([ts, -_rate(ds, db)])
          }

          // USDT Buy / Sell ratio
          if (ub > us) {
            options.series[11].data.push([ts, _rate(ub, us)])
          } else {
            options.series[12].data.push([ts, -_rate(us, ub)])
          }
        })
      this.options = options
    },
  },
}
</script>
