<template>
  <div>
    <chart :options="options" constructor-type="stockChart" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { pointsOptions } from './_pointsOptions'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'MarketPointsChart',

  components: {
    Chart,
  },

  data: () => ({
    points: [],
    options: null,
  }),

  async created() {
    this.options = cloneDeep(pointsOptions)
    this.points = await this.$http.get('/api/v1/markets/points')
    this.options.series[0].data = this.points
    await this.fetchPrices()
  },

  methods: {
    async fetchPrices() {
      let from = this.points[0][0] / 1e3
      const to = this.points[this.points.length - 1][0] / 1e3
      while (from < to) {
        const prices = await this.$http.get(
          '/api/v1/markets/prices/v2/public/index-price-kline',
          {
            params: {
              symbol: 'BTCUSD',
              interval: 5,
              from,
            },
          }
        )

        prices.forEach((price) => {
          this.options.series[1].data.push([
            price.open_time * 1e3,
            parseFloat(price.close),
          ])
          from = price.open_time
        })
      }
    },
  },
}
</script>
