<template>
  <div class="w-full">
    <c-raw-table
      height="780px"
      :headers="['Time', 'Source', 'Price', 'Title']"
      :items="news"
    >
      <template v-slot:header="{ headers }">
        <tr>
          <th
            v-for="(item, key) in headers"
            :key="key"
            class="raw-table-header"
          >
            {{ item }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <td class="raw-table-item font-bold">
          {{ item.timestamp | formatDate }}
        </td>

        <td class="raw-table-item">
          {{ item.site }}
        </td>

        <td class="raw-table-item">
          {{ item.price | formatNumber }}
        </td>

        <td class="raw-table-item">
          <a :href="item.link" target="_blank">
            {{ item.title }}
          </a>
        </td>
      </template>
    </c-raw-table>
  </div>
</template>

<script>
const INTERVAL_TIME = 30e3

export default {
  name: 'News',
  data() {
    return {
      news: [],
      timer: null,
    }
  },
  async created() {
    await this.fetch()
    this.timer = setInterval(this.fetch, INTERVAL_TIME)
  },
  methods: {
    async fetch() {
      const news = await this.$http.get('/api/v1/markets/news')
      news.sort((a, b) => {
        return b.timestamp - a.timestamp
      })
      this.news = news
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>
