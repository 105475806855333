<template>
  <div class="w-full">
    <c-raw-table :headers="tableHeaders" :items="getMessages" height="780px">
      <template v-slot:header="{ headers }">
        <tr>
          <th
            v-for="(item, key) in headers"
            :key="key"
            class="raw-table-header uppercase"
          >
            {{ item }}
          </th>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <td class="raw-table-item">
          {{ item.time | formatDate }}
        </td>

        <td class="raw-table-item">
          {{ item.btcusd | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.btc.vol | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.btc.day_hig | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.btc.day_low | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.btc.week_hig | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.btc.week_low | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.lxvx.vol | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.lxvx.day_hig | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.lxvx.day_low | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.lxvx.week_hig | formatNumber }}
        </td>

        <td class="raw-table-item">
          {{ item.lxvx.week_low | formatNumber }}
        </td>

        <td class="raw-table-item">
          <a
            class="px-2 py-2 rounded bg-gray-400"
            :href="`https://discord.com/channels/488195891220447243/740225181133570070/${item.message_id}`"
            target="_blank"
            >Open</a
          >
        </td>
      </template>
    </c-raw-table>
  </div>
</template>

<script>
const headers = [
  'time',
  'btc-usd',
  'btc vol',
  'btc day high',
  'btc day low',
  'btc week high',
  'btc week low',
  'lxvx vol',
  'lxvx day high',
  'lxvx day low',
  'lxvx week high',
  'lxvx week low',
  'link',
]

export default {
  name: 'DiscordIvBands',
  data() {
    return {
      tableHeaders: headers,
      messages: [],
    }
  },
  created() {
    this.$http.get('/api/market/discord').then((response) => {
      this.messages = response
    })
  },
  computed: {
    getMessages() {
      if (!this.messages) {
        return []
      }

      return Object.keys(this.messages)
        .map((timestamp) => {
          return {
            time: parseInt(timestamp) * 1000,
            ...this.messages[timestamp],
          }
        })
        .sort((a, b) => b.time - a.time)
        .slice(0, 50)
    },
  },
}
</script>
