<template>
  <div class="flex w-full flex-col">
    <divider />
    <div class="border border-lg bg-gray-900 shadow-lg">
      <chart :options="chartOptions" constructor-type="stockChart" />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import cloneDeep from 'lodash.clonedeep'
import chartOptions from './_chartsOptions'
import Divider from '@/components/layout/Divider'

const list = ['busd', 'pax', 'usdc', 'husd', 'usdt']
const duration = 60 * 86400000

function _sort(a, b) {
  return b[0] - a[0]
}

function _formatNumber(val) {
  const arr = val.toString().split('.')
  return (
    arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (arr[1] ? '.' + arr[1] : '')
  )
}

function _index(series, name) {
  return series.findIndex((el) => el.name === name)
}

export default {
  name: 'Charts',
  components: {
    Chart,
    Divider,
  },
  data() {
    return {
      index: {},
      wbtcData: [],
      liquidation: [],
      grayScaleData: [],
      whaleAlertData: [],
      chartOptions: cloneDeep(chartOptions),
      data: [...Array(17)].map(() => []),
    }
  },
  methods: {
    indexSeries() {
      list.forEach((symbol) => {
        symbol = symbol.toUpperCase()
        this.index[symbol] = this.chartOptions.series.findIndex(
          (el) => el.name === symbol
        )
      })
    },
    async afterSetExtremes(event) {
      if (!this.loading && event.min <= event.dataMin) {
        this.loading = true
        const { chart } = event.target
        chart.showLoading('Loading data from server...')
        const to = event.dataMin - 1e3
        const from = to - duration
        const result = await this.$http.get(
          `/api/chart?resolution=D&from=${from}&to=${to}`
        )
        result.reverse().forEach((el) => {
          this.data[0].unshift([el[0], el[1], el[2], el[3], el[4]])
          this.data[1].unshift([el[0], el[5]])
        })
        chart.series[0].setData(this.data[0])
        chart.series[1].setData(this.data[1])

        const _filter = function (el) {
          return el[0] > from && el[0] < to
        }

        // Update stable coins
        const tmp = this.whaleAlertData.filter(_filter)
        list.map((symbol) => {
          const idx = this.index[symbol.toUpperCase()]
          if (idx) {
            tmp
              .filter((el) => el[2] === symbol)
              .forEach((el) => {
                this.data[idx].unshift(el)
              })
            chart.series[idx].setData(this.data[idx])
          }
        })
        chart.hideLoading()
        chart.redresult()
        setTimeout(() => (this.loading = false), 2e3)
      }
    },
    async getData() {
      this.chartOptions = cloneDeep(chartOptions)
      this.chartOptions.xAxis.events = {
        afterSetExtremes: this.afterSetExtremes,
      }
      const to = Date.now()
      const from = to - duration
      const result = await this.$http.get(
        `/api/chart?resolution=D&from=${from}&to=${to}`
      )
      let min = Infinity
      result
        .sort(_sort)
        .reverse()
        .forEach((el) => {
          min = Math.min(min, el[3] - 1e3)
          this.data[0].push([el[0], el[1], el[2], el[3], el[4]])
          this.data[1].push([el[0], el[5]])
        })
      this.chartOptions.xAxis.range = duration / 2
      this.chartOptions.yAxis[0].min = min
      this.chartOptions.series[0].data = this.data[0]
      this.chartOptions.series[1].data = this.data[1]
      await Promise.all([
        this.getWBTC(from),
        this.getGrayScaleData(from),
        this.getWhaleAlert(from),
        this.getLiquidation(),
      ])
    },
    async getWhaleAlert(from) {
      const result = await this.$http.get('/api/v1/markets/whale-alert-daily')
      this.whaleAlertData = Object.values(result)
        .map((el) => [el.timestamp, Math.round(el.amount_btc), el.symbol])
        .sort(_sort)
      const tmp = this.whaleAlertData.filter((el) => el[0] > from)
      list.map((symbol) => {
        const idx = this.index[symbol.toUpperCase()]
        if (idx) {
          this.data[idx] = tmp
            .filter((el) => el[2] === symbol)
            .map((el) => [el[0], el[1]])
            .reverse()
          this.chartOptions.series[idx].data = this.data[idx]
        }
      })
    },
    async getWBTC(from) {
      const result = await this.$http.get('/api/wbtc?type=daily')
      this.wbtcData = Object.values(result)
        .map(({ timestamp, amount }) => [timestamp, Math.round(amount / 1e8)])
        .sort(_sort)
      const idx = _index(this.chartOptions.series, 'WBTC')
      this.data[idx] = this.wbtcData.filter((el) => el[0] > from).reverse()
      this.chartOptions.series[idx].data = this.data[idx]
    },
    async getGrayScaleData(from) {
      const ONE_DAY = 86400000
      const result = await this.$http.get('/api/market/bitcoin-trust')
      let max = -1,
        min = 1
      this.grayScaleData = Object.values(result)
        .filter((el) => el.total)
        .map(({ date, total = 0 }) => {
          if (total) {
            if (!result[date - ONE_DAY]) {
              return [date, 0]
            }
            if (!result[date - ONE_DAY].total) {
              return [date, 0]
            }
            let diff = total - result[date - ONE_DAY].total
            if (diff === -134) {
              diff = -1431
            }
            max = Math.max(diff, max)
            min = Math.min(diff, min)
            return [date, diff]
          }
        })
        .filter(Boolean)
        .sort(_sort)
      const idx = _index(this.chartOptions.series, 'Grayscale BTC')
      this.data[idx] = this.grayScaleData.filter((el) => el[0] > from).reverse()
      this.chartOptions.series[idx].data = this.data[idx]
      this.chartOptions.yAxis[4].min = -500
      this.chartOptions.yAxis[4].max = Math.round(max * 1.1)
    },
    async getLiquidation() {
      const result = await this.$http.get('/api/market/liquidation')

      this.liquidation = Object.values(
        result.reduce((acc, el) => {
          const x = el.timestamp - (el.timestamp % 86400000)
          const key = el.exchange + x
          if (!(key in acc)) {
            acc[key] = {
              x,
              exchange: el.exchange,
              Buy: 0,
              Sell: 0,
            }
          }
          acc[key][el.side] += el.volume
          return acc
        }, {})
      )
        .map(({ x, exchange, Buy, Sell }) => ({
          x,
          title: `${exchange}`,
          text: `${exchange} losscut: Buy: ${_formatNumber(
            Buy
          )} / Sell: ${_formatNumber(Sell)}`,
        }))
        .reverse()
      const idx = _index(this.chartOptions.series, 'Liquidation')
      this.chartOptions.series[idx].data = this.liquidation
    },
  },
  mounted() {
    this.indexSeries()
    this.getData()
  },
}
</script>
