<template>
  <div class="flex w-full flex-col">
    <divider />
    <div class="chart-wrapper border-xl">
      <chart :options="bojOptions" />
    </div>

    <divider />
    <div class="chart-wrapper border-xl">
      <chart :options="h6Options" />
    </div>

    <divider />
    <div class="chart-wrapper border-xl">
      <chart :options="h41Options" />
    </div>

    <divider />
    <div class="chart-wrapper border-xl">
      <chart :options="inflationOptions" />
    </div>

    <divider />
    <div class="chart-wrapper border-xl">
      <chart :options="btcOptions" />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import options from './_releasesOptions'
import cloneDeep from 'lodash.clonedeep'

function _fix2(val) {
  return Math.round(val * 100) / 100
}

export default {
  name: 'Releases',
  components: {
    Chart,
  },
  data() {
    return {
      bojOptions: cloneDeep(options.boj),
      h6Options: cloneDeep(options.h60),
      h41Options: cloneDeep(options.h41),
      inflationOptions: cloneDeep(options.inflation),
      btcOptions: cloneDeep(options.btcc),
    }
  },
  methods: {
    updateBojOptions(boj) {
      const check = {}
      Object.keys(boj).forEach((key) => {
        Object.entries(boj[key])
          .reverse()
          .forEach(([category, { M1, M2 }]) => {
            if (!check[category]) {
              check[category] = 1
              this.bojOptions.xAxis.categories.unshift(category)
              this.bojOptions.series[0].data.unshift(_fix2(M1))
              this.bojOptions.series[1].data.unshift(_fix2(M2))
              this.bojOptions.series[2].data.unshift(_fix2(M1 + M2))
            }
          })
      })
    },
    updateH6Options({ h6 }) {
      Object.keys(h6)
        .sort()
        .forEach((key) => {
          const { date, M1, M2 } = h6[key]
          if (date.length > 10) {
            this.h6Options.xAxis.categories.push(date.slice(0, -6))
            this.h6Options.series[0].data.push(_fix2(M1))
            this.h6Options.series[1].data.push(_fix2(M2))
            this.h6Options.series[2].data.push(_fix2(M1 + M2))
          }
        })
    },
    updateH41Options({ h41 }) {
      Object.keys(h41)
        .sort()
        .forEach((key) => {
          const { date, value } = h41[key]
          this.h41Options.xAxis.categories.push(date.slice(0, -6))
          this.h41Options.series[0].data.push(_fix2(value))
        })
    },
    updateInflationOptions(inflation) {
      Object.keys(inflation)
        .sort()
        .forEach((key) => {
          const { date, inflationRate } = inflation[key]
          this.inflationOptions.xAxis.categories.push(date.slice(5))
          this.inflationOptions.series[0].data.push(_fix2(inflationRate * 100))
        })
    },
    updateBtccOptions(data) {
      Object.keys(data)
        .sort()
        .forEach((key) => {
          const { date, value } = data[key]
          this.btcOptions.xAxis.categories.push(date.slice(0, 5))
          this.btcOptions.series[0].data.push(_fix2(value))
        })
    },
  },
  async created() {
    const list = [
      '/api/v1/markets/boj',
      '/api/market/fed',
      '/api/market/inflation',
      '/api/market/btcc-total-value',
    ]
    Promise.all(list.map((path) => this.$http.get(path))).then(
      ([boj, fed, inflation, btcc]) => {
        this.updateBojOptions(boj)
        this.updateH6Options(fed)
        this.updateH41Options(fed)
        this.updateInflationOptions(inflation)
        this.updateBtccOptions(btcc)
      }
    )
  },
}
</script>

<style lang="css" scoped>
.chart-wrapper {
  @apply border;
  @apply bg-gray-900;
  @apply shadow-lg;
}
</style>
