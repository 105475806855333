<template>
  <div class="flex w-full flex-col" v-if="ready">
    <divider />
    <div>
      <div>【BTCUSD】: ${{ price | formatNumber }}</div>
      <div class="flex">
        <div>
          <div>【Bitvol】: {{ bitvol | formatNumber }}</div>
          <div>【Lxvx】: {{ lxvx | formatNumber }}</div>
        </div>

        <div class="ml-6">
          <div>
            【Day】: ${{ bands.bll | formatNumber }} ~ ${{
              bands.bhh | formatNumber
            }}
          </div>
          <div>
            【Day】: ${{ bands.lll | formatNumber }} ~ ${{
              bands.lhh | formatNumber
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="border border-lg bg-gray-900 shadow-lg">
      <chart :options="chartOptions" constructor-type="stockChart" />
    </div>
  </div>
</template>

<script>
import { lxvxOptions } from './_lxvxOptions.js'
import { Chart } from 'highcharts-vue'
import cloneDeep from 'lodash.clonedeep'
const k = Math.sqrt(365)

export default {
  name: 'Lxvx',
  components: {
    Chart,
  },
  data: () => ({
    ready: false,
    chartOptions: null,
    price: 0,
    lxvx: 0,
    bitvol: 0,
    bands: {},
  }),
  async mounted() {
    this.ready = false
    await this.fetch()
    this.ready = true
  },
  methods: {
    async fetchLxvx() {
      const to = Date.now()
      const from = to - 7.5 * global.ONE_DAY
      const url = '/api/v1/markets/proxy-lxvx/api/markets/lxvx'
      return this.$http.get(url, { params: { from, to } })
    },
    async fetchPrices() {
      const url = '/api/v1/markets/prices/kline/list'
      const symbol = 'BTCUSD'
      const resolution = 60
      const mark = Date.now() - 7 * global.ONE_DAY
      const from = (mark - (mark % global.ONE_DAY) - global.ONE_HOUR) / 1e3
      const to = parseInt(Date.now() / 1e3)
      const prices = await this.$http.get(url, {
        params: { symbol, resolution, from, to },
      })
      return prices
        .map((el) => [
          el.time * 1e3,
          parseFloat(el.open),
          parseFloat(el.high),
          parseFloat(el.low),
          parseFloat(el.close),
        ])
        .sort((a, b) => a[0] - b[0])
    },
    async fetch() {
      const data = await this.fetchLxvx()
      const prices = await this.fetchPrices()
      this.price = prices[prices.length - 1][4]
      this.chartOptions = cloneDeep(lxvxOptions)
      let bhh, bhl, blh, bll, lhh, lhl, llh, lll, close
      prices.forEach((el) => {
        const timestamp = el[0]
        this.chartOptions.series[0].data.push(el.slice(0, 5))
        if (timestamp % global.ONE_DAY === 0) {
          const { lxvx, bitvol } =
            data.find((el) => el.timestamp === timestamp) || {}
          const bRate = (100 * bitvol) / k
          const lRate = (100 * lxvx) / k
          this.lxvx = lxvx * 100
          this.bitvol = bitvol * 100
          this.chartOptions.series[5].data.push([timestamp, this.bitvol])
          this.chartOptions.series[6].data.push([timestamp, this.lxvx])
          bhl = Math.round((close * (100 + bRate)) / 100)
          bhh = bhl + 1000
          lhl = Math.round((close * (100 + lRate)) / 100)
          lhh = lhl + 1000
          llh = Math.round((close * (100 - lRate)) / 100)
          lll = llh - 1000
          blh = Math.round((close * (100 - bRate)) / 100)
          bll = blh - 1000
        }
        close = el[4]
        this.chartOptions.series[1].data.push([timestamp, bhl, bhh])
        this.chartOptions.series[2].data.push([timestamp, bll, blh])
        this.chartOptions.series[3].data.push([timestamp, lhl, lhh])
        this.chartOptions.series[4].data.push([timestamp, lll, llh])
      })
      this.bands = { bhh, bhl, blh, bll, lhh, lhl, llh, lll }
    },
  },
}
</script>
