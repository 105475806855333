<template>
  <div class="w-full">
    <c-toggle-btn label="Show chart" v-model="showChart" class="my-2" />
    <c-raw-table
      v-if="!showChart"
      :headers="tableHeader"
      :items="map"
      class="w-full"
      height="880px"
    >
      <template v-slot:header="{ headers }">
        <tr>
          <th
            v-for="(item, key) in headers"
            :key="key"
            class="raw-table-header"
          >
            {{ item }}
          </th>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <td class="raw-table-item font-bold text-left">
          {{ item.name }}
        </td>

        <td class="raw-table-item">
          <template v-if="item.input">
            <div class="flex justify-center">
              <input
                v-model="data[item.key]"
                class="border-solid border rounded-md border-4 pl-2 w-12"
              />
              <c-icon
                name="device-floppy"
                class="cursor-pointer"
                @click="save"
              />
            </div>
          </template>
          <template v-else>
            {{ data[item.key] }}
          </template>
        </td>

        <td class="raw-table-item">
          <div class="flex justify-between">
            <span v-html="item.method" />
            <template v-if="item.setting">
              <span class="flex justify-center flex-row">
                <input
                  v-model="ref"
                  class="border-solid border rounded-md border-4 pl-2 w-12"
                />
                <c-icon
                  name="device-floppy"
                  class="cursor-pointer"
                  @click="save"
                />
              </span>
            </template>
          </div>
        </td>

        <td class="raw-table-item">
          {{ item.scope }}
        </td>
      </template>
    </c-raw-table>

    <points-chart v-if="showChart" />
  </div>
</template>

<script>
import CToggleBtn from '../kit/button/CToggleBtn.vue'
import PointsChart from './PointsChart.vue'
const map = [
  // {
  //   name: 'HAT',
  //   input: true,
  //   point: 20,
  //   method: '手動入力',
  //   scope: '-30 ~ +30',
  //   ref: '更新するまで過去の数字が反映。',
  // },
  // {
  //   name: '室伏',
  //   input: true,
  //   point: 20,
  //   method: '手動入力',
  //   scope: '-15 〜 +15',
  //   ref: '更新するまで過去の数字が反映。',
  // },
  {
    key: 'usdtusd_0',
    name: 'USDTUSD 元本割れ',
    point: 20,
    method: 'USDTUSDが1.000以下の場合には、(USDT - 1.0000) × 10,000',
    scope: '最小値: -20',
    ref: '1.000を割れるとマイナス評価。',
  },
  {
    key: 'usdtusd_1',
    name: 'USDTUSD 1時間前比較',
    point: 20,
    method: 'USDTUSD-USDTUSD[1時間前] × 10,000',
    scope: '-20 〜 +20',
  },
  {
    key: 'usdtusd_2',
    name: 'USDTUSD 24時間前比較',
    point: 20,
    method: 'USDTUSD-USDTUSD[24時間前] × 10,000',
    scope: '-20 〜 +20',
  },
  // {
  //   setting: true,
  //   name: 'GRAY-SCALE乖離率 (乖離率 - 設定値) × -3',
  //   point: 20,
  //   method: '(GRAY-SCALE乖離率 - 設定値) × -3',
  //   scope: '-30 〜 +30',
  // },
  {
    key: 'preRate',
    name: 'Pre-FR',
    point: 20,
    method:
      'BTCUSDのPre-FRの最大値2つの平均、最小値2つの平均を計算し、<br/>絶対値を比較し大きい方の実数を判定値とする。判定値 × (-500)',
    scope: '-50 〜 +50',
  },
  {
    key: 'btc_future_1',
    name: 'BTC Future1',
    point: 20,
    method: 'マイナス時のみ有効：Basis × -10,000',
    scope: '0 〜 +30',
  },
  {
    key: 'btc_future_2',
    name: 'BTC Future2',
    point: 20,
    method: 'マイナス時のみ有効：Basis × -20,000',
    scope: '0 〜 +30',
  },
  {
    key: 'btc_future_3',
    name: 'BTC Future3',
    point: 20,
    method: 'マイナス時のみ有効：Basis × -30,000',
    scope: '0 〜 +30',
  },
  {
    key: 'eth_future_1',
    name: 'ETH Future1',
    point: 20,
    method: 'マイナス時のみ有効：Basis × -10,000',
    scope: '0 〜 +15',
  },
  {
    key: 'busd',
    name: 'BUSD',
    point: 20,
    method: '1,000,000USD以上発行した時刻。<br/>(24-経過時間)×発行量/3,000,000',
    scope: '0 〜 +15',
  },
  {
    key: 'usdt',
    name: 'USDT',
    point: 20,
    method:
      '100,000,000USD以上発行した時刻。<br/>(24-経過時間)×発行量/100,000,000',
    scope: '0 〜 +15',
  },
  {
    key: 'pax',
    name: 'PAX',
    point: 20,
    method: '1,000,000USD以上発行した時刻。<br/>(24-経過時間)×発行量/3,000,000',
    scope: '0 〜 +15',
  },
  {
    key: 'eth_rate',
    name: 'ETH価格比較',
    point: 20,
    method:
      '100 × 「(ETH価格 -  1時間前ETH価格)/1時間前ETH価格」<br/>-「(BTC価格 -  1時間前BTC価格)/1時間前BTC価格)」',
    scope: '-50 〜 +50',
  },
  {
    key: 'bond_rate',
    name: '米金利比較',
    point: 20,
    method:
      '100 × 「(米金利  -  1時間前米金利 )/1時間前米金利」<br/>-「(BTC価格 -  1時間前BTC価格)/1時間前BTC価格)」',
    scope: '-50 〜 +50',
  },
  {
    key: 'sp500_rate',
    name: 'SP500先物比較',
    point: 20,
    method:
      '1,000 × 「(SP500先物 -  1時間前SP500先物)/1時間前SP500先物」<br/>-「(BTC価格 -  1時間前BTC価格)/1時間前BTC価格)」',
    scope: '-50 〜 +50',
  },
  {
    key: 'gold_rate',
    name: 'GOLD比較',
    point: 20,
    method:
      '100 × 「(GOLD価格 - 1時間前 GOLD価格)/1時間前 GOLD価格)」<br/>-「(BTC価格 -  1時間前BTC価格)/1時間前BTC価格)」',
    scope: '-50 〜 +50',
  },
  {
    key: 'td9_1h',
    name: '1時間足のTD　セットアップ7,8,9',
    point: 20,
    method:
      '・1時間足で4本前の終値が現在終値より安い状態が7本以上続いたら、(続いた本数-6)×3 <br/> ・1時間足で4本前の終値が現在終値より高い状態が7本以上続いたら、(続いた本数-6)×-3',
    scope: '-50 〜 +50',
  },
  {
    key: 'td9_1d',
    name: '日足のTD　セットアップ7,8,9',
    point: 20,
    method:
      '・1日足で4本前の終値が現在終値より安い状態が7本以上続いたら、(続いた本数-6)×5<br/> ・1日足で4本前の終値が現在終値より高い状態が7本以上続いたら、(続いた本数-6)×-5',
    scope: '-50 〜 +50',
  },
]

export default {
  components: { PointsChart, CToggleBtn },
  name: 'Points',
  data() {
    return {
      tableHeader: ['項目目', '点数', '計算方法', '数値範囲'],
      map,
      data: {},
      total: 0,
      showChart: false,
    }
  },
  async created() {
    this.data = await this.$http.get('/api/v1/markets/market-points')
  },
}
</script>
