<template>
  <bar-card>
    <div class="flex flex-wrap justify-center">
      <router-link class="title md:text-xl" to="/markets">
        CRYPTO QUANT
      </router-link>
    </div>
    <div class="bar-body my-0.5 sm:my-2 space-y-2">
      <div
        class="bar-item flex flex-col"
        v-for="{ label, list, classes } in group"
        :key="label"
        :class="classes"
      >
        <div class="w-full flex justify-center font-bold underline">
          {{ label }}
        </div>
        <div class="w-full flex flex-col space-y-0.5">
          <div
            v-for="({ label, val }, idx) in list"
            :key="idx"
            class="bar-item-band"
          >
            <span>{{ label }}</span>
            <span>{{ val | formatNumber }}</span>
          </div>
        </div>
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/markets/BarCard'
import { _class } from './index.js'

export default {
  name: 'CryptoQuant',
  components: {
    BarCard,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    cryptoquant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    group() {
      return [
        {
          label: 'BTC Spot Exchange',
          classes: this.btcSpot.class,
          list: [
            {
              label: 'Balance - BTC',
              val: this.btcSpot.val,
            },
            {
              label: 'Change - BTC',
              val: this.btcSpot.change,
            },
          ],
        },
        {
          label: 'Stable-coin Spot Exchange',
          classes: this.stableSpot.class,
          list: [
            {
              label: 'Balance - USD',
              val: this.stableSpot.val,
            },
            {
              label: 'Change - USD',
              val: this.stableSpot.change,
            },
            {
              label: 'Balance - USD → BTC',
              val: this.stableSpotInBtc.val,
            },
            {
              label: 'Change - USD → BTC',
              val: this.stableSpotInBtc.change,
            },
          ],
        },
        {
          label: 'BTC Derv Exchange',
          classes: this.btcDerivative.class,
          list: [
            {
              label: 'Balance - BTC',
              val: this.btcDerivative.val,
            },
            {
              label: 'Change - BTC',
              val: this.btcDerivative.change,
            },
          ],
        },
        {
          label: 'Stable-coin Derv Exchange',
          classes: this.stableDerivative.class,
          list: [
            {
              label: 'Balance - USD',
              val: this.stableDerivative.val,
            },
            {
              label: 'Change - USD',
              val: this.stableDerivative.change,
            },
            {
              label: 'Balance - USD → BTC',
              val: this.stableDerivativeInBtc.val,
            },
            {
              label: 'Change - USD → BTC',
              val: this.stableDerivativeInBtc.change,
            },
          ],
        },
      ]
    },
    reserve() {
      return {
        now: this.cryptoquant.reserve,
        prev: this.cryptoquant.reserve_9am,
      }
    },
    stableReserve() {
      return {
        now: this.cryptoquant.stable_reserve,
        prev: this.cryptoquant.stable_reserve_9am,
      }
    },
    btcSpot() {
      /*
        CRYPTO QUANT DERIVATIVE SPOTで２分割
          SPOT
          対象：変化率
          上限：+0.5%：赤
          下限：-0.5%：緑
      */
      const val = Math.round(this.reserve.now.spot)
      const base = Math.round(this.reserve.prev.spot)
      const change = val - base
      const rate = change / base
      return {
        val,
        change,
        class: _class((-100 * rate) / base, { mid: 0, diff: 0.5 }),
      }
    },
    btcDerivative() {
      /*
        https://thanmtsvcb.slack.com/archives/DL97S0GUB/p1617839510003100
        CRYPTO QUANT DERIVATIVE SPOTで２分割
          DERIVATIVE
          対象：変化率
          上限：+0.5%：赤
          下限：-0.5%：緑
      */
      const val = Math.round(this.reserve.now.derivative)
      const base = Math.round(this.cryptoquant.reserve_9am.derivative)
      const change = val - base
      return {
        val,
        change,
        class: _class((-100 * change) / base, { mid: 0, diff: 0.5 }),
      }
    },
    stableSpot() {
      /*
        https://thanmtsvcb.slack.com/archives/DL97S0GUB/p1620268668002700
          ・CRYPTO QUANTの取得データ計算式変更

        https://thanmtsvcb.slack.com/archives/DL97S0GUB/p1617936140011400
        [ CRYPTO QUANT ]
          ・Reserve Spot USD
          対象：数値
          上限：+1,000,000,000USD：緑
          下限：-1,000,000,000USD：赤
          ・Reserve USD
          対象：数値
          上限：+2,000,000,000USD：緑
          下限：-2,000,000,000USD：赤
          を追加
      */
      const val = Math.round(this.stableReserve.now.spot_usd)
      const base = Math.round(this.stableReserve.prev.spot_usd)
      const change = val - base
      return {
        val,
        change,
        class: _class(val, { mid: 0, diff: 1e9 }),
      }
    },
    stableSpotInBtc() {
      const val = Math.round(this.stableReserve.now.spot)
      const base = Math.round(this.stableReserve.prev.spot)
      return {
        val,
        change: val - base,
      }
    },
    stableDerivative() {
      /*
        https://thanmtsvcb.slack.com/archives/DL97S0GUB/p1617936140011400
        [ CRYPTO QUANT ]
          ・Reserve USD
          対象：数値
          上限：+2,000,000,000USD：緑
          下限：-2,000,000,000USD：赤
          を追加
      */
      const val = Math.round(this.stableReserve.now.derivative_usd)
      const base = Math.round(this.stableReserve.prev.derivative_usd)
      const change = val - base
      return {
        val,
        change,
        class: _class(val, { mid: 0, diff: 2e9 }),
      }
    },
    stableDerivativeInBtc() {
      const val = Math.round(this.stableReserve.now.derivative)
      const base = Math.round(this.stableReserve.prev.derivative)
      return {
        val,
        change: val - base,
      }
    },
  },
}
</script>

<style lang="css" src="./style.css" scoped />
