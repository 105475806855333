const pointsOptions = {
  chart: {
    height: 500,
  },
  legend: {
    enabled: true,
  },
  rangeSelector: {
    enabled: false,
  },
  title: {
    text: 'Market points',
  },
  xAxis: {
    type: 'datetime',
  },
  yAxis: [
    {
      lineWidth: 2,
      resize: { enabled: true },
      opposite: true,
    },
    {
      opposite: false,
      offset: 0,
      lineWidth: 2,
    },
  ],
  series: [
    {
      name: 'Points',
      data: [],
      yAxis: 0,
    },
    {
      name: 'Prices',
      data: [],
      yAxis: 1,
      color: '#f89f65',
      tooltip: { valuePrefix: '$ ' },
    },
  ],
}

module.exports = { pointsOptions }
