var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col"},[_c('c-tabs',{staticClass:"mb-4",attrs:{"tabs":_vm.tabs},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}),(_vm.tab.key === _vm.tabKeys.STABLE_COIN)?[_c('page-sub-title',[_vm._v(" Stable coins")]),_c('div',{staticClass:"overflow-y-auto"},[_c('c-raw-table',{attrs:{"headers":_vm.stableCoinHeaders,"items":_vm.mints,"height":"380px"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(item,key){return _c('th',{key:key,staticClass:"raw-table-header"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"raw-table-item font-bold"},[_vm._v(" "+_vm._s(item.symbol.toUpperCase())+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.amount))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("date")(item.timestamp || '-'))+" ")])]}}],null,false,3823497905)})],1)]:_vm._e(),(_vm.tab.key === _vm.tabKeys.TRANSFER)?[_c('page-sub-title',{staticClass:"mt-4"},[_vm._v(" Transfers")]),_c('div',{staticClass:"overflow-y-auto"},[_c('c-raw-table',{attrs:{"headers":_vm.transfersHeaders,"items":_vm.transfers,"height":"390px"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(item,key){return _c('th',{key:key,staticClass:"raw-table-header"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"raw-table-item font-bold uppercase"},[_vm._v(" "+_vm._s(item.from.owner || 'UNKNOWN')+" ")]),_c('td',{staticClass:"raw-table-item uppercase"},[_vm._v(" "+_vm._s(item.to.owner || 'UNKNOWN')+" ")]),_c('td',{staticClass:"raw-table-item uppercase"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.amount))+" ("+_vm._s(item.symbol)+") ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price || '-'))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("date")((item.timestamp * 1e3)))+" ")])]}}],null,false,1738944271)})],1)]:_vm._e(),(_vm.tab.key === _vm.tabKeys.LIQUIDATION)?[_c('page-sub-title',[_vm._v(" Liquidation")]),_c('div',{staticClass:"overflow-y-auto w-full"},[_c('c-raw-table',{attrs:{"headers":_vm.liquidationHeaders,"items":_vm.liquidation,"height":"800px"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(item,key){return _c('th',{key:key,staticClass:"raw-table-header"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"raw-table-item font-bold uppercase"},[_vm._v(" "+_vm._s(item.exchange)+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.volume))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(item.side)+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("date")(item.timestamp || '-'))+" ")])]}}],null,false,1361579547)})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }