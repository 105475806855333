const crytoquantOptions = {
  chart: {
    height: 500,
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: ['viewFullscreen'],
      },
    },
  },
  legend: {
    enabled: true,
  },
  rangeSelector: {
    enabled: false,
  },
  plotOptions: {
    series: {
      tooltip: {
        valueSuffix: '',
        valueDecimals: 0,
      },
    },
  },
  title: {
    text: 'Cryptoquant',
  },
  yAxis: [
    {
      opposite: false,
      title: {
        text: 'Netflow Total',
      },
    },
    {
      opposite: true,
      title: {
        text: 'Reverse',
      },
    },
    {
      opposite: true,
      title: {
        text: 'Reverse USD',
      },
      visible: false,
    },
    {
      opposite: true,
      title: {
        text: 'Premium index',
      },
      visible: false,
    },
    {
      opposite: true,
      title: {
        text: 'BTCUSD',
      },
      visible: false,
    },
    {
      opposite: true,
      title: {
        text: 'Reverse Spot',
      },
    },
  ],
  xAxis: {
    min: Date.now() - 7 * 86400e3,
    type: 'datetime',
  },
  series: [
    {
      visible: false,
      name: 'Netflow Total',
      color: '#23bee9',
      data: [],
    },
    {
      name: 'Reserve',
      data: [],
      color: '#5abf1b',
      tooltip: { valuePrefix: '₿ ' },
      yAxis: 1,
    },
    {
      name: 'Reserve USD',
      data: [],
      color: '#9c30ca',
      tooltip: { valuePrefix: '$ ' },
      yAxis: 2,
    },
    {
      name: 'Coinbase premium index',
      data: [],
      zones: [
        {
          value: 50,
          color: '#ea533c',
        },
        {
          color: '#3c7cea',
        },
      ],
      yAxis: 3,
    },
    {
      name: 'BTCUSD',
      data: [],
      color: '#ff9900',
      tooltip: { valuePrefix: '$ ' },
      yAxis: 4,
    },
    {
      visible: false,
      name: 'Netflow Total - Spot',
      color: '#0b6c87',
      tooltip: { valuePrefix: '₿ ' },
      data: [],
    },
    {
      name: 'Reserve - Spot',
      data: [],
      color: '#307008',
      tooltip: { valuePrefix: '₿ ' },
      yAxis: 5,
    },
    {
      name: 'Reserve USD - Spot',
      data: [],
      color: '#3a094f',
      tooltip: { valuePrefix: '$ ' },
      yAxis: 2,
    },
    {
      name: 'Reserve - Total',
      data: [],
      color: '#296107',
      tooltip: { valuePrefix: '₿ ' },
      yAxis: 1,
    },
    {
      name: 'Reserve USD - Total',
      data: [],
      color: '#c49110',
      tooltip: { valuePrefix: '$ ' },
      yAxis: 2,
    },
  ],
}

module.exports = { crytoquantOptions }
