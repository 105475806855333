module.exports = {
  created() {
    this.$http.get('/api/v1/markets/liquidation').then((data) => {
      this.liquidation = data.slice(0, 200).map((item) => ({
        side: item.side,
        price: item.price,
        volume: item.volume,
        timestamp: item.timestamp,
        exchange: item.exchange,
      }))
    })

    this.$http.get('/api/v1/markets/whale-alert-mint').then((data) => {
      this.mints = data.map((item) => ({
        ...item,
        timestamp: item.timestamp * 1e3,
      }))
    })

    this.$http.get('/api/v1/markets/whale-alert-transfer').then((data) => {
      this.transfers = data.map((item) => ({
        ...item,
        timestamp: item.timestamp * 1e3,
      }))
    })
  },
}
