<template>
  <div class="w-full flex space-x-2">
    <div class="w-1/2">
      <page-sub-title> Stable coins </page-sub-title>
      <div class="overflow-y-auto">
        <c-raw-table :headers="stableCoinHeaders" :items="mints" height="380px">
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold">
              {{ item.symbol.toUpperCase() }}
            </td>

            <td class="raw-table-item">
              {{ item.amount | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.price | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.timestamp || '-' | date }}
            </td>
          </template>
        </c-raw-table>
      </div>

      <page-sub-title class="mt-4"> Transfers </page-sub-title>
      <div class="overflow-y-auto">
        <c-raw-table
          :headers="transfersHeaders"
          :items="transfers"
          height="390px"
        >
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold uppercase">
              {{ item.from.owner || 'UNKNOWN' }}
            </td>

            <td class="raw-table-item uppercase">
              {{ item.to.owner || 'UNKNOWN' }}
            </td>

            <td class="raw-table-item uppercase">
              {{ item.amount | formatNumber }} ({{ item.symbol }})
            </td>

            <td class="raw-table-item">
              {{ item.price || '-' | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ (item.timestamp * 1e3) | date }}
            </td>
          </template>
        </c-raw-table>
      </div>
    </div>

    <div class="flex w-1/2 flex-col">
      <page-sub-title> Liquidation </page-sub-title>
      <div class="overflow-y-auto w-full">
        <c-raw-table
          :headers="liquidationHeaders"
          :items="liquidation"
          height="800px"
        >
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold uppercase">
              {{ item.exchange }}
            </td>

            <td class="raw-table-item">
              {{ item.volume | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.side }}
            </td>

            <td class="raw-table-item">
              {{ item.price | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.timestamp || '-' | date }}
            </td>
          </template>
        </c-raw-table>
      </div>
    </div>
  </div>
</template>

<script>
import createdMixin from '../../mixins/colection'
const transfersHeaders = ['From', 'To', 'Amount', 'Price', 'Date']
const stableCoinHeaders = ['Symbol', 'Amount (USD)', 'Price', 'Date']
const liquidationHeaders = ['Exchange', 'Volume (USD)', 'Side', 'Price', 'Date']
export default {
  name: 'Collections',
  mixins: [createdMixin],
  data() {
    return {
      mints: [],
      transfers: [],
      liquidation: [],
      transfersHeaders,
      stableCoinHeaders,
      liquidationHeaders,
    }
  },
}
</script>
