<template>
  <div class="flex w-full flex-col">
    <divider />
    <div class="border border-lg shadow-lg">
      <chart
        :options="chartOptions"
        constructor-type="stockChart"
        v-if="ready"
      />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import cloneDeep from 'lodash.clonedeep'
import chartOptions from './_treasuriesOptions'
import {
  getTreasuriesTime,
  getRandomColor,
  defaultCompanyChartOption,
} from './_treasuriesOptions'

export default {
  name: 'Treasuries',
  components: {
    Chart,
  },
  data() {
    return {
      ready: false,
      chartOptions: {},
    }
  },
  created() {
    this.chartOptions = cloneDeep(chartOptions)
    this.$http.get('/api/market/treasuries').then((res) => {
      let visited = {}
      res.forEach((item) => {
        const date = getTreasuriesTime(item.date)
        this.chartOptions.series[0].data.push([date, parseInt(item.total)])
        const extData = item.data

        extData.forEach(({ company, btc }) => {
          if (visited[company]) {
            const extIndex = visited[company]
            this.chartOptions.series[extIndex].data.push([
              date,
              parseFloat(btc),
            ])
          } else {
            const newLen = this.chartOptions.series.push(
              cloneDeep({
                name: company,
                color: getRandomColor(),
                ...cloneDeep(defaultCompanyChartOption),
              })
            )
            visited[company] = newLen - 1
          }
        })
        this.ready = true
      })
    })
  },
}
</script>
