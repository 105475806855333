var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full flex-col",on:{"click":_vm.hide}},[_c('c-toggle-btn',{staticClass:"font-bold",attrs:{"label":"自動更新"},model:{value:(_vm.autoUpdate),callback:function ($$v) {_vm.autoUpdate=$$v},expression:"autoUpdate"}}),_c('div',{staticClass:"flex w-full flex-col mb-4"},[_c('divider'),_c('div',{key:_vm.counter,staticClass:"border border-lg bg-gray-900 shadow-lg"},[_c('chart',{attrs:{"options":_vm.options,"constructor-type":"stockChart"}})],1)],1),(_vm.$isMobile)?_c('c-tabs',{staticClass:"mb-4",attrs:{"tabs":_vm.tabs},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}):_vm._e(),_c('div',{staticClass:"flex"},[(!_vm.$isMobile || _vm.tab.key === 'ALL')?_c('div',{staticClass:"overflow-y-auto md:w-1/2 w-full m-2"},[(!_vm.$isMobile)?_c('page-sub-title',[_vm._v(" 全ブロックデータ ")]):_vm._e(),_c('c-raw-table',{attrs:{"headers":_vm.headers.left,"items":_vm.all,"height":"380px"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(item,key){return _c('th',{key:key,staticClass:"raw-table-header"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"raw-table-item font-bold"},[_vm._v(" "+_vm._s(_vm._f("date")(item.timestamp))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.price || '-')))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" A: "+_vm._s(_vm._f("formatNumber")(item.total))+" "),_c('br'),_vm._v(" S: "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.totalSpot || '-')))+" "),(item.totalDeravative)?[_c('br'),_vm._v(" D: "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.totalDeravative || '-')))+" ")]:_vm._e()],2),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.reserve)))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.reserveSpot || '-')))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.reserve - item.reserveSpot || '-')))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(item.premium)+" "),(item.rate)?[_c('br'),_vm._v(" "+_vm._s(_vm._f("fix2")(item.rate || '-'))+" % ")]:_vm._e()],2)]}}],null,false,4112437250)})],1):_vm._e(),(!_vm.$isMobile || _vm.tab.key === 'LARGE')?_c('div',{staticClass:"overflow-y-auto md:w-1/2 w-full m-2"},[(!_vm.$isMobile)?_c('page-sub-title',[_vm._v(" 1,000以上データ ")]):_vm._e(),_c('c-raw-table',{attrs:{"headers":_vm.headers.right,"items":_vm.large,"height":"380px"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(item,key){return _c('th',{key:key,staticClass:"raw-table-header"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"raw-table-item font-bold"},[_vm._v(" "+_vm._s(_vm._f("date")(item.timestamp))+" ")]),_c('td',{staticClass:"raw-table-item"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price || '-'))+" ")]),_c('td',{staticClass:"raw-table-item relative cursor-pointer",on:{"mouseover":function($event){return _vm.display(item)},"click":function($event){return _vm.display(item)}}},[_vm._v(" A: "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.total || '-')))+" "),_c('br'),_vm._v(" S: "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.totalSpot || '-')))+" "),(item.totalDeravative)?[_c('br'),_vm._v(" D: "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.totalDeravative || '-')))+" ")]:_vm._e(),(_vm.tooltip === item.blockheight)?_c('div',{staticClass:"absolute w-64 h-64 top-0 right-0 z-10 p-2 text-sm mr-10 md:mr-32 mt-2 text-white bg-orange-500 rounded-lg shadow-lg overflow-scroll"},[_c('div',{staticClass:"flex justify-between text-lg"},[_c('span',[_vm._v(" TOTAL: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(item.total || '-')))+" ")])]),_c('divider'),_vm._l((Object.entries(item.detail)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{key:key,staticClass:"flex justify-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.exchanges[key])+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fix2")(value || '-'))))])])})],2):_vm._e()],2)]}}],null,false,2163487263)})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }