import { mapGetters } from 'vuex'
import News from '@/components/markets/News'
import Lxvx from '@/components/markets/Lxvx'
import Lxvx2 from '@/components/markets/Lxvx2'
import CTabs from '@/components/kit/tabs/CTabs'
import Points from '@/components/markets/Points'
import Charts from '@/components/markets/Charts'
import XChart from '@/components/markets/XChart'
import Twitter from '@/components/markets/Twitter'
import BuySell from '@/components/markets/BuySell'
import Binance from '@/components/markets/Binance'
import EtfBtcc from '@/components/markets/Etf-btcc'
import Releases from '@/components/markets/Releases'
import PageTitle from '@/components/layout/PageTitle'
import Statistics from '@/components/markets/Statistics'
import Treasuries from '@/components/markets/Treasuries'
import Collections from '@/components/markets/Collections'
import Cryptoquant from '@/components/markets/Cryptoquant'
import MarketBar from '@/components/markets/MarketBar/index.vue'
import DiscordIvBands from '@/components/markets/DiscordIvBands'
import CollectionsMobile from '@/components/markets/CollectionMobile'

const NEWS = 'NEWS'
const LXVX = 'LXVX'
const LXVX2 = 'LXVX2'
const CHARTS = 'CHARTS'
const TWITTER = 'TWITTER'
const RELEASE = 'RELEASE'
const X_CHART = 'X_CHART'
const ETF_BTCC = 'ETF_BTCC'
const BUY_SELL = 'BUY_SELL'
const TREASURIES = 'TREASURIES'
const STATISTICS = 'STATISTICS'
const CRYPTOQUANT = 'CRYPTOQUANT'
const COLLECTIONS = 'COLLECTIONS'
const MARKET_POINTS = 'MARKET_POINTS'
const BINANCE_STATS = 'BINANCE_STATS'
const DISCORD_IV_BANDS = 'DISCORD_IV_BANDS'

const map = {
  [STATISTICS]: Statistics,
  [CRYPTOQUANT]: Cryptoquant,
  [NEWS]: News,
  [LXVX]: Lxvx,
  [LXVX2]: Lxvx2,
  [TWITTER]: Twitter,
  [ETF_BTCC]: EtfBtcc,
  [MARKET_POINTS]: Points,
  [RELEASE]: Releases,
  [CHARTS]: Charts,
  [BINANCE_STATS]: Binance,
  [TREASURIES]: Treasuries,
  [DISCORD_IV_BANDS]: DiscordIvBands,
  [X_CHART]: XChart,
  [BUY_SELL]: BuySell,
}

const tabs = [
  {
    title: 'Collections',
    key: COLLECTIONS,
    hash: 'collections',
  },
  {
    title: 'Statistics',
    key: STATISTICS,
    hash: 'statistics',
  },
  {
    title: 'News',
    key: NEWS,
    hash: 'news',
  },
  {
    title: 'Lxvx',
    key: LXVX,
    hash: 'lxvx',
  },
  { 
    title: 'Lxvx2',
    key: LXVX2,
    hash: 'lxvx2',
  },
  {
    title: 'Cryptoquant',
    key: CRYPTOQUANT,
    hash: 'cryptoquant',
  },
  {
    title: 'Twitter',
    key: TWITTER,
    hash: 'twitter',
  },
  {
    title: 'Market Points',
    key: MARKET_POINTS,
    hash: 'market-points',
  },
  {
    title: 'Release',
    key: RELEASE,
    hash: 'release',
  },
  {
    title: 'Binance Stats',
    key: BINANCE_STATS,
    hash: 'binance-stats',
  },
  {
    title: 'Charts',
    key: CHARTS,
    hash: 'charts',
  },
  {
    title: 'Bitcoin Treasuries',
    key: TREASURIES,
    hash: 'bitcoin-treasuries',
  },
  {
    title: 'Discord - IV Bands',
    key: DISCORD_IV_BANDS,
    hash: 'discord-iv-bands',
  },
  {
    title: 'X Chart',
    key: X_CHART,
    hash: 'x-chart',
  },
  {
    title: 'BUY / SELL',
    key: BUY_SELL,
    hash: 'buy-sell',
  },
]

export default {
  name: 'Markets',
  components: {
    CTabs,
    MarketBar,
    PageTitle,
  },
  props: {
    priceNotificationSpan: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    tabs,
    display: true,
    tab: null,
    point: 0,
    timer: null,
  }),
  created() {
    const hash = location.hash
    if (hash) {
      this.tab = this.tabs.find((tab) => `#${tab.hash}` === hash)
    }
    if (!this.tab) {
      this.tab = this.tabs[1]
      location.hash = `#${this.tab.hash}`
    }
  },
  mounted() {
    this.fetchPoints()
    this.timer = setInterval(() => this.fetchPoints(), 10e3)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onTabChange(tab) {
      location.hash = tab.hash
    },
    async fetchPoints() {
      const data = await this.$http.get('/api/v1/markets/market-points')
      this.point = Object.values(data).reduce((acc, el) => acc + el, 0)
    },
  },
  computed: {
    ...mapGetters({ appWidth: 'getAppWidth' }),
    notification() {
      return this.$store.state.system.volume > 0 ? 'ON' : 'OFF'
    },
    component() {
      if (this.tab.key === COLLECTIONS) {
        if (this.appWidth < 1066) {
          return CollectionsMobile
        }
        return Collections
      }
      return map[this.tab.key] || Statistics
    },
  },
}
