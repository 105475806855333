<template>
  <div
    class="flex flex-wrap text-base border-2 rounded shadow-sm flex-col mr-1 mt-1 p-1 w-11/12 md:w-4/12 rounded-xl sm:p-2 md:p-2 sm:mr-2 sm:mt-2"
  >
    <div class="flex flex-wrap justify-center">
      <router-link class="title md:text-xl" to="/markets">
        Statistics
      </router-link>
    </div>
    <div class="flex flex-row">
      <div class="bar-body my-0.5 sm:my-2 divide-y space-y-2">
        <div class="bar-item flex flex-col">
          <div class="w-full flex flex-col space-y-0.5 mr-1">
            <small-bar-card
              label="USDTUSD"
              :classes="usdtusd.class"
              :cur="usdtusd.now"
              :prev="usdtusd.last"
              link="https://www.bitfinex.com/t/UST:USD"
            />

            <small-bar-card
              label="USDRMB"
              :classes="usdrmb.class"
              :cur="usdrmb.now"
              :prev="usdrmb.last"
              link="https://jp.investing.com/currencies/usd-cny-chart"
            />

            <small-bar-card
              label="SP500"
              :classes="sp500.class"
              :cur="sp500.now"
              :prev="sp500.last"
              link="https://jp.investing.com/indices/us-spx-500"
            />

            <small-bar-card
              label="VIX"
              :classes="vix.class"
              :cur="vix.now"
              :prev="vix.last"
              link="https://jp.investing.com/indices/volatility-s-p-500"
            />
          </div>
        </div>
      </div>

      <div class="bar-body my-0.5 sm:my-2 divide-y space-y-2 ml-1">
        <div class="bar-item flex flex-col">
          <div class="w-full flex flex-col space-y-0.5">
            <small-bar-card
              label="BOND"
              :classes="bond.class"
              :cur="bond.now"
              :prev="bond.last"
              link="https://jp.investing.com/rates-bonds/u.s.-10-year-bond-yield"
            />

            <small-bar-card
              label="GOLD"
              :classes="gold.class"
              :cur="gold.now"
              :prev="gold.last"
              link="https://jp.investing.com/commodities/gold/"
            />

            <small-bar-card
              label="Hash-rate"
              :classes="hashrate.class"
              :cur="hashrate.now"
              :prev="hashrate.last"
              link="https://www.blockchain.com/ja/charts/hash-rate"
            />

            <small-bar-card
              label="MEMPOOL"
              :classes="mempool.class"
              :cur="mempool.now"
              :prev="mempool.last"
              link="https://www.blockchain.com/ja/charts/mempool-size"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _rate } from './index.js'
import SmallBarCard from '@/components/markets/MarketBar/SmallCard'

export default {
  name: 'statistic',
  components: { SmallBarCard },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    usdrmb() {
      /*・USDRMB
        対象：変化率
        上限：+3%：緑
        下限：-3%：赤
      */
      const { PREV_USDRMB, USDRMB } = this.data.investing
      return _rate(USDRMB, PREV_USDRMB, { mid: 1, diff: 0.03 })
    },
    sp500() {
      /*・SP500
        対象：変化率
        上限：+3%：緑
        下限：-3%：赤
      */
      const { PREV_SP500, SP500 } = this.data.investing
      return _rate(SP500, PREV_SP500, { mid: 1, diff: 0.03 })
    },
    vix() {
      /*・VIX
        対象：変化率
        上限：+5%：赤
        下限：-5%：緑
      */
      const { PREV_VIX, VIX } = this.data.investing
      return _rate(VIX, PREV_VIX, { mid: 1, diff: 0.05 }, true)
    },
    bond() {
      /*・BOND
        対象：変化率
        上限：+5%：緑
        下限：-5%：赤
      */
      const { PREV_BOND_10Y, BOND_10Y } = this.data.investing
      return _rate(BOND_10Y, PREV_BOND_10Y, { mid: 1, diff: 0.05 })
    },
    gold() {
      /*・GOLD
        対象：変化率
        上限：+5%：緑
        下限：-5%：赤
      */
      const { PREV_GOLD, GOLD } = this.data.investing
      return _rate(GOLD, PREV_GOLD, { mid: 1, diff: 0.05 })
    },
    hashrate() {
      /*・HASH-RATE
        対象：変化率
        上限：+5%：緑
        下限：-5%：赤
      */
      const { now, last } = this.data.blockchain.hashrate
      return _rate(now, last, { mid: 1, diff: 0.05 })
    },
    mempool() {
      /*・MEMPOOL
        対象：変化率
        上限：+5%：緑
        下限：-5%：赤
      */
      const { now, last } = this.data.blockchain.mempool
      return _rate(now, last, { mid: 1, diff: 0.05 })
    },
    usdtusd() {
      /*・USDTUSD
        対象：対象値
        上限：1.001：緑
        下限：9.995：赤
      */
      return {
        ..._rate(this.data.usdtusd.now, this.data.usdtusd.last, {
          diff: 0.003, // (1.001 - 0.995) / 2,
          mid: 0.998, // (1.001 + 0.995) / 2,
        }),
      }
    },
  },
}
</script>

<style lang="css" src="./style.css" scoped />
