const colors = ['#058DC7', '#50B432', '#1aadce']
const chart = {
  type: 'spline',
}
const bojUrl = 'https://www.boj.or.jp/statistics/money/ms/index.htm/'
const bojSubtitle = `Source: <a href="${bojUrl}">日本銀行</a>`

const h6Url = 'https://www.federalreserve.gov/releases/h6/current/'
const h6Subtitle = `Source: <a href="${h6Url}">Federal Reserve System</a>`

const h41Url = 'https://www.federalreserve.gov/releases/h41/current/h41.htm'
const h41Subtitle = `Source: <a href="${h41Url}">Federal Reserve System</a>`

const inflationUrl =
  'https://www.bloomberg.co.jp/markets/rates-bonds/government-bonds/us'
const inflationSubtitle = `Source: <a href="${inflationUrl}">Bloomberg: 米国債・金利</a>`

const btccUrl = 'https://www.bloomberg.co.jp/quote/BTCC/B:CN'
const btccSubtitle = `Source: <a href="${btccUrl}">Bloomberg: BTCC/B:CN TORONTO</a>`

const series = [
  {
    data: [],
    name: 'M1',
    marker: { symbol: 'square' },
  },
  {
    data: [],
    name: 'M2',
    marker: { symbol: 'diamond' },
  },
  {
    data: [],
    name: 'M1+M2',
    marker: { symbol: 'triangle' },
  },
]

const options = {
  boj: {
    chart,
    colors,
    series,
    title: { text: 'マネーストック速報' },
    subtitle: { text: bojSubtitle },
    xAxis: { categories: [], crosshair: true },
    yAxis: { min: 500 },
    tooltip: { shared: true },
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
  },
  h60: {
    chart,
    colors,
    series,
    title: { text: 'Money Stock Measures. Billions of dollars.' },
    subtitle: { text: h6Subtitle },
    xAxis: { categories: [] },
    yAxis: {
      title: { text: 'Bil.$' },
      labels: { formatter: () => this.value },
    },
    tooltip: { crosshairs: true, shared: true },
    plotOptions: {
      spline: { marker: { radius: 4, lineColor: '#666666', lineWidth: 1 } },
    },
  },
  h41: {
    chart,
    colors,
    series: [{ data: [], name: 'M1', marker: { symbol: 'square' } }],
    title: { text: 'Factors Affecting Reserve Balances' },
    subtitle: { text: h41Subtitle },
    xAxis: { categories: [] },
    yAxis: {
      title: { text: 'Mil.$' },
      labels: { formatter: () => this.value },
    },
    tooltip: { crosshairs: true, shared: true },
    plotOptions: {
      spline: { marker: { radius: 4, lineColor: '#666666', lineWidth: 1 } },
    },
  },
  inflation: {
    chart,
    colors,
    title: { text: '期待インフレ率' },
    subtitle: { text: inflationSubtitle },
    xAxis: { categories: [], crosshair: true },
    yAxis: { title: { text: '%' } },
    series: [{ name: '期待インフレ率', data: [] }],
    tooltip: { valueSuffix: '%', useHTML: false },
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
  },
  btcc: {
    chart,
    colors,
    title: { text: 'Purpose Bitcoin ETF' },
    subtitle: { text: btccSubtitle },
    xAxis: { categories: [], crosshair: true },
    yAxis: { title: { text: 'BTC' } },
    series: [{ name: '資産総額', data: [] }],
    tooltip: { valueSuffix: '₿', useHTML: false },
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
  },
}

module.exports = options
