<template>
  <div class="flex w-full flex-col" @click="hide">
    <c-toggle-btn class="font-bold" label="自動更新" v-model="autoUpdate" />
    <div class="flex w-full flex-col mb-4">
      <divider />
      <div class="border border-lg bg-gray-900 shadow-lg" :key="counter">
        <chart :options="options" constructor-type="stockChart" />
      </div>
    </div>
    <c-tabs :tabs="tabs" v-model="tab" class="mb-4" v-if="$isMobile" />
    <div class="flex">
      <div
        class="overflow-y-auto md:w-1/2 w-full m-2"
        v-if="!$isMobile || tab.key === 'ALL'"
      >
        <page-sub-title v-if="!$isMobile"> 全ブロックデータ </page-sub-title>
        <c-raw-table :headers="headers.left" :items="all" height="380px">
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold">
              {{ item.timestamp | date }}
            </td>

            <td class="raw-table-item">
              {{ item.price || '-' | fix2 | formatNumber }}
            </td>

            <td class="raw-table-item">
              A: {{ item.total | formatNumber }}
              <br />
              S: {{ item.totalSpot || '-' | fix2 | formatNumber }}
              <template v-if="item.totalDeravative">
                <br />
                D: {{ item.totalDeravative || '-' | fix2 | formatNumber }}
              </template>
            </td>

            <td class="raw-table-item">
              {{ item.reserve | fix2 | formatNumber }}
              <br />
              {{ item.reserveSpot || '-' | fix2 | formatNumber }}
              <br />
              {{ item.reserve - item.reserveSpot || '-' | fix2 | formatNumber }}
            </td>

            <td class="raw-table-item">
              {{ item.premium }}
              <template v-if="item.rate">
                <br />
                {{ item.rate || '-' | fix2 }} %
              </template>
            </td>
          </template>
        </c-raw-table>
      </div>
      <div
        class="overflow-y-auto md:w-1/2 w-full m-2"
        v-if="!$isMobile || tab.key === 'LARGE'"
      >
        <page-sub-title v-if="!$isMobile"> 1,000以上データ </page-sub-title>
        <c-raw-table :headers="headers.right" :items="large" height="380px">
          <template v-slot:header="{ headers }">
            <tr>
              <th
                v-for="(item, key) in headers"
                :key="key"
                class="raw-table-header"
              >
                {{ item }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <td class="raw-table-item font-bold">
              {{ item.timestamp | date }}
            </td>

            <td class="raw-table-item">
              {{ item.price || '-' | formatNumber }}
            </td>

            <td
              class="raw-table-item relative cursor-pointer"
              @mouseover="display(item)"
              @click="display(item)"
            >
              A: {{ item.total || '-' | fix2 | formatNumber }}
              <br />
              S: {{ item.totalSpot || '-' | fix2 | formatNumber }}
              <template v-if="item.totalDeravative">
                <br />
                D: {{ item.totalDeravative || '-' | fix2 | formatNumber }}
              </template>
              <div
                class="absolute w-64 h-64 top-0 right-0 z-10 p-2 text-sm mr-10 md:mr-32 mt-2 text-white bg-orange-500 rounded-lg shadow-lg overflow-scroll"
                v-if="tooltip === item.blockheight"
              >
                <div class="flex justify-between text-lg">
                  <span> TOTAL: </span>
                  <span> {{ item.total || '-' | fix2 | formatNumber }} </span>
                </div>
                <divider />
                <div
                  v-for="[key, value] in Object.entries(item.detail)"
                  :key="key"
                  class="flex justify-between"
                >
                  <span> {{ exchanges[key] }}: </span>
                  <span> {{ value || '-' | fix2 | formatNumber }}</span>
                </div>
              </div>
            </td>
          </template>
        </c-raw-table>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { Chart } from 'highcharts-vue'

import { crytoquantOptions } from './_cryptoquantOptions'

const tabs = [
  {
    title: '全ブロック',
    key: 'ALL',
  },
  {
    title: '1,000以上',
    key: 'LARGE',
  },
]

function _sortValues(data) {
  const arr = Object.values(data)
  arr.sort((a, b) => a.timestamp - b.timestamp)
  return arr
}

export default {
  data: () => ({
    autoUpdate: false,
    tabs: [...tabs],
    tab: tabs[1],
    tooltip: 0,
    counter: 1,
    options: {},
    exchanges: {
      all_exchange: 'All Exchanges',
      spot_exchange: 'Spot Exchanges',
      derivative_exchange: 'Derivative Exchanges',
      bibox: 'Bibox',
      binance: 'Binance',
      bilaxy: 'Bilaxy',
      biki_com: 'BiKi',
      bitfinex: 'Bitfinex',
      bitflyer: 'Bitflyer',
      bitforex: 'Bitforex',
      bithumb: 'Bithumb',
      bitmex: 'BitMEX',
      bitmax: 'BitMax',
      bitstamp: 'Bitstamp',
      bittrex: 'Bittrex',
      bw: 'BW',
      bybit: 'Bybit',
      catex: 'Catex',
      cobinhood: 'Cobinhood',
      coinbase: 'Coinbase',
      coinbase_pro: 'Coinbase Pro',
      coinbit: 'Coinbit',
      coinex: 'Coinex',
      coinone: 'Coinone',
      coinhako: 'Coinhako',
      crex24: 'Crex24',
      dcoin: 'Dcoin',
      deribit: 'Deribit',
      eterbase: 'Eterbase',
      exmo: 'Exmo',
      foundryusapool: 'foundryUSAPool',
      ftx: 'FTX',
      gate_io: 'Gate.io',
      gemini: 'Gemini',
      gopax: 'Gopax',
      hitbtc: 'HitBTC',
      hotbit: 'Hotbit',
      huobi_global: 'Huobi Global',
      indodax: 'Indodax',
      itbit: 'Itbit',
      korbit: 'Korbit',
      kraken: 'Kraken',
      kucoin: 'Kucoin',
      lubian: 'Lubian',
      mercatox: 'MeRcatox',
      mt_gox: 'Mt. Gox',
      mxc: 'MXC',
      okex: 'OKEx',
      paribu: 'Paribu',
      peatio: 'Peatio',
      poloniex: 'Poloniex',
    },
    headers: {
      left: ['Time', 'BTCUSD', 'Netflow', 'Reserve', 'Coinbase Premium Index'],
      right: ['Time', 'BTCUSD', 'Netflow'],
    },
    all: [],
    large: [],
  }),
  components: {
    Chart,
  },
  methods: {
    display(item) {
      if (this.tooltip === 0 && item.detail) {
        setTimeout(() => (this.tooltip = item.blockheight), 30)
      }
    },
    hide() {
      if (this.tooltip > 0) {
        setTimeout(() => (this.tooltip = 0), 30)
      }
    },
    async refresh() {
      const LIMIT = 200
      const all = []
      const large = []
      const options = cloneDeep(crytoquantOptions)
      const [data, coinbase] = await Promise.all([
        this.$http.get('/api/v1/markets/cryptoquant-history'),
        this.$http.get('/api/v1/markets/cryptoquant-coinbase'),
      ])

      let arr = _sortValues(coinbase)
      arr.forEach(({ timestamp, coinbase_premium_index }) => {
        options.series[3].data.push([timestamp, coinbase_premium_index])
      })

      arr = _sortValues(data)
      const len = arr.length
      const limit = len - LIMIT - 1
      arr.forEach((item, idx) => {
        const {
          blockheight,
          netflow_total,
          reserve_usd,
          reserve,
          netflow_total_spot,
          netflow_total_derivative,
          reserve_usd_spot,
          reserve_spot,
          timestamp,
          price,
          premium,
          rate,
          detail,
        } = item

        options.series[0].data.push([timestamp, netflow_total])
        options.series[1].data.push([timestamp, reserve])
        options.series[2].data.push([timestamp, reserve_usd])
        options.series[4].data.push([timestamp, price])
        if (netflow_total_spot) {
          options.series[5].data.push([timestamp, netflow_total_spot])
        }
        if (reserve_spot) {
          options.series[6].data.push([timestamp, reserve_spot])
          options.series[8].data.push([timestamp, reserve + reserve_spot])
        }
        if (reserve_usd_spot) {
          options.series[7].data.push([timestamp, reserve_usd_spot])
          options.series[9].data.push([
            timestamp,
            reserve_usd + reserve_usd_spot,
          ])
        }

        const total = Math.round(netflow_total)
        const reserveSpot = reserve_spot ? Math.round(reserve_spot) : undefined
        const totalSpot = Math.round(netflow_total_spot)
        const totalDeravative = netflow_total_derivative
          ? Math.round(netflow_total_derivative)
          : undefined
        if (Math.abs(total) > 1000) {
          large.push({
            blockheight,
            timestamp,
            total,
            totalSpot,
            totalDeravative,
            price,
            detail: {
              spot_exchange: netflow_total_spot,
              derivative_exchange: netflow_total_derivative,
              ...detail,
            },
            display: false,
          })
        }
        if (idx > limit) {
          all.push({
            timestamp,
            total,
            price,
            reserve,
            reserveSpot,
            totalSpot,
            totalDeravative,
            premium,
            rate,
          })
        }
      })
      this.options = options
      this.all = all.reverse()
      this.large = large.reverse()
      this.counter++
    },
  },
  watch: {
    autoUpdate(val) {
      if (val) {
        this.interval = setInterval(this.refresh, 60e3)
      } else {
        clearInterval(this.interval)
      }
    },
  },
  mounted() {
    this.refresh()
    if (this.autoUpdate) {
      this.interval = setInterval(this.refresh, 60e3)
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
}
</script>
