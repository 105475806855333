<template>
  <bar-card>
    <div class="flex flex-wrap justify-center">
      <a
        class="uppercase md:text-xl font-bold text-center pt-2"
        href="https://www.bitmex.com/app/trade/XBTUSD"
        target="_blank"
      >
        FUTURE
      </a>
    </div>
    <div class="bar-body my-1 sm:my-2 divide-y">
      <div
        class="bar-item pt-1"
        v-for="{ symbol, last, index, basic, classes } in futures"
        :class="classes"
        :key="symbol"
      >
        <div class="w-full font-bold">{{ symbol }}</div>
        <div class="w-full text-right space-x-2">
          <div>
            <label>{{ last | fix2 | formatNumber }}</label>
          </div>
          <div class="flex">
            <label>{{ index | fix2 | formatNumber }}</label>
            (<label>{{ basic | percentage2 }}</label
            >)
          </div>
        </div>
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/markets/BarCard'
import { _calc } from './index.js'

function _data([symbol, { last, index, basic }]) {
  let range = {
    mid: -0.025,
    diff: 0.075,
  }
  if (symbol === 'XBTM21') {
    range = {
      mid: -0.01,
      diff: 0.04,
    }
  }
  const res = _calc(-basic, range)
  return { symbol, last, index, basic, classes: res.class }
}

export default {
  name: 'Future',
  components: {
    BarCard,
  },
  props: {
    future: {
      type: Object,
      required: true,
    },
  },
  computed: {
    futures() {
      const { binance, bitmex, bybit } = this.future
      const list = [
        ...Object.entries(bitmex),
        ...Object.entries(bybit),
        ...Object.entries(binance),
      ].filter((item) => item[0] !== 'updated')
      return list.map(_data)
    },
  },
  methods: {},
}
</script>

<style lang="css" src="./style.css" scoped />
