<template>
  <div class="w-full">
    <c-raw-table
      :headers="tableHeaders"
      :items="getStats"
      class="stats-table"
      height="870px"
    >
      <template v-slot:header="{ headers }">
        <tr>
          <th class="raw-table-header-sticky z-20" ref="headerExName" id="hEx">
            Exchange
          </th>
          <th class="raw-table-header z-10" :style="`left: ${marginHeader}px`">
            Symbol
          </th>
          <th
            v-for="(item, key) in headers"
            :key="key"
            class="raw-table-header"
          >
            {{ item }}
          </th>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <td class="raw-table-item-sticky" :class="item.expiry ? 'expire' : ''">
          {{ item.exchange.replaceAll('-', ' ') }}
        </td>

        <td
          class="raw-table-item font-bold sticky bg-gray-400"
          :class="item.expiry ? 'expire' : ''"
          :style="`left: ${marginHeader}px`"
        >
          {{ item.symbol.replaceAll('-', ' ') }}
        </td>

        <td class="raw-table-item" :class="item.expiry ? 'expire' : ''">
          {{ item.last | fix2 | formatNumber }}
        </td>

        <td class="raw-table-item" :class="item.expiry ? 'expire' : ''">
          {{ item.index | fix2 | formatNumber }}
        </td>

        <td
          class="raw-table-item"
          :class="`${_basicColor(item.basic)} ${item.expiry ? 'expire' : ''}`"
        >
          {{ item.basic | percentage4 }}
        </td>

        <td class="raw-table-item" :class="item.expiry ? 'expire' : ''">
          {{ item.vwap | fix2 | formatNumber }}
        </td>

        <td
          class="raw-table-item"
          :class="`${_basicColor(item.vwBasic)} ${item.expiry ? 'expire' : ''}`"
        >
          {{ item.vwBasic | percentage4 }}
        </td>

        <td
          class="raw-table-item"
          :class="`${_fundingColor(item.rate)} ${item.expiry ? 'expire' : ''}`"
        >
          {{ item.rate | percentage4 }}
        </td>

        <td
          class="raw-table-item"
          :class="`${_fundingColor(item.preRate)} ${
            item.expiry ? 'expire' : ''
          }`"
        >
          {{ item.preRate | percentage4 }}
        </td>

        <td
          class="raw-table-item"
          :class="`${_yieldColor(item.yield)} ${item.expiry ? 'expire' : ''}`"
        >
          {{ item.yield | percentage2 }}
        </td>

        <td
          class="raw-table-item"
          :class="`${_interestColor(item.interest)} ${
            item.expiry ? 'expire' : ''
          }`"
        >
          {{ item.interest | bigNumber }}
        </td>

        <td
          class="raw-table-item"
          :class="`${_volumeColor(item.volume)} ${item.expiry ? 'expire' : ''}`"
        >
          {{ item.volume | bigNumber }}
        </td>

        <td class="raw-table-item" :class="item.expiry ? 'expire' : ''">
          {{ item.expiry || '-' }}
        </td>
      </template>
    </c-raw-table>
  </div>
</template>

<script>
const headers = [
  'Price',
  'Index Price',
  'Basic',
  'Vwap',
  'Vwap Rate',
  'FR',
  'Pre-FR',
  'Yield',
  'Interest',
  'Volume(24H)',
  'Expiry',
]
const lsKey = 'STATISTIC'
const eps = 1e-6

export default {
  name: 'Statistics',
  data() {
    return {
      tableHeaders: headers,
      data: null,
      interval: null,
      marginHeader: 0,
    }
  },
  created() {
    window.addEventListener('load', () => {
      // after all dom adjusted
      this.marginHeader = document
        .getElementById('hEx')
        .getBoundingClientRect().width
    })
    try {
      const data = localStorage.getItem(lsKey)
      this.data = JSON.parse(data)
    } catch (err) {
      this.data = null
    }
    this.interval = setInterval(this.fetchStatisticsData, 2e3)
  },
  methods: {
    async fetchStatisticsData() {
      try {
        this.data = await this.$http.get('/api/market/stats')
        localStorage.setItem(lsKey, JSON.stringify(this.data))
      } catch (err) {
        // ignore
      }
    },
    _basicColor(val) {
      if (val) {
        const res = val > 0 ? 'green-' : 'red-'
        const abs = Math.abs(val)
        return res + (abs > 1e-3 ? 3 : abs > 1e-4 ? 2 : 1)
      }
      return 'none'
    },
    _fundingColor(val) {
      if (typeof val !== 'number' || Math.abs(val) < eps) {
        return 'none'
      }
      const res = val > 0 ? 'green-' : 'red-'
      const abs = Math.abs(val)
      return res + (abs > 2e-4 ? 3 : abs > 1e-4 ? 2 : 1)
    },
    _yieldColor(val) {
      if (typeof val !== 'number' || Math.abs(val) < eps) {
        return 'none'
      }
      const res = val > 0 ? 'green-' : 'red-'
      const abs = Math.abs(val)
      return res + (abs > 6e-2 ? 3 : abs > 3e-2 ? 2 : 1)
    },
    _interestColor(val) {
      if (val > 1e4) {
        const abs = Math.abs(val)
        return 'yellow-' + (abs > 1e8 ? 3 : abs > 1e6 ? 2 : 1)
      }
      return 'none'
    },
    _volumeColor(val) {
      if (val > 1e5) {
        const abs = Math.abs(val)
        return 'yellow-' + (abs > 1e9 ? 3 : abs > 1e6 ? 2 : 1)
      }
      return 'none'
    },
  },
  computed: {
    getStats() {
      if (!this.data) {
        return []
      }

      let items = []
      items = items.concat(
        Object.keys(this.data).map((ex) => {
          let exItems = []
          exItems = exItems.concat(
            Object.keys(this.data[ex]).map((symbol) => {
              return {
                exchange: ex,
                symbol: [symbol],
                ...this.data[ex][symbol],
              }
            })
          )
          return exItems
        })
      )
      let result = []
      items.forEach(
        (item) => (result = [...result, ...item.map((_item) => _item)])
      )
      result.sort((a, b) => a.order - b.order)
      return result
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
    localStorage.setItem(lsKey, JSON.stringify(this.data))
  },
}
</script>

<style lang="scss" scoped>
.stats-table {
  height: 100%;

  .expire {
    background: #b5caea;
  }

  .red-1 {
    background: #ffe8e6;
  }

  .red-2 {
    background: #f7978f;
  }

  .red-3 {
    background: #f55a4d;
  }

  .green-1 {
    background: #e1fff0;
  }

  .green-2 {
    background: #abffcb;
  }

  .green-3 {
    background: #25ff7a;
  }

  .yellow-1 {
    background: #fffce1;
  }

  .yellow-2 {
    background: #ffffbf;
  }

  .yellow-3 {
    background: #fbfb3e;
  }
}
</style>
