<template>
  <div
    class="h-20 flex text-base border-2 rounded shadow-sm flex-col mr-1 mt-1 p-1 w-full rounded-xl sm:p-2 md:p-2 sm:mr-2 sm:mt-2"
    :class="classes"
  >
    <slot>
      <div class="flex flex-wrap">
        <a
          class="uppercase md:text-xl text-center pt-2"
          :href="link"
          target="_blank"
        >
          <span class="font-bold">{{ label }}</span>
        </a>
        <div
          v-if="prev"
          class="ml-auto flex"
          :class="isDown ? 'text-red-600' : 'text-green-700'"
        >
          <c-icon
            class="bg-white h-full"
            v-if="isDown"
            name="trending-down"
            color="red"
            height="18"
            width="18"
          />
          <c-icon
            v-else
            class="bg-white h-full"
            name="trending-up"
            height="18"
            width="18"
          />
          <div class="bg-white text-sm flex items-center h-full">
            <span> {{ percentChange }}% </span>
          </div>
        </div>
      </div>
      <div class="md:text-xl text-center font-light">
        {{ cur | formatNumber }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'SmallBarCard',
  props: {
    label: {
      type: String,
      required: false,
    },
    link: {
      type: String,
    },
    cur: {
      type: Number,
      require: false,
    },
    prev: {
      type: Number,
      require: false,
    },
    classes: {
      type: String,
    },
  },
  computed: {
    isDown() {
      return this.prev > this.cur
    },
    percentChange() {
      if (this.prev) {
        return Math.round(10000 * (this.cur / this.prev - 1)) / 100
      }
      return 0
    },
  },
}
</script>
