<template>
  <div v-if="data" class="flex flex-wrap justify-center">
    <exchanges-price
      :premium="data.coinbase.premium"
      :prices="data.bybit.prices"
    />
    <crypto-quant
      :price="price"
      :cryptoquant="misc.cryptoquant"
      v-if="misc.cryptoquant"
    />
    <long-short :lsChecker="misc['ls-checker']" v-if="misc['ls-checker']" />
    <iv-band
      v-if="!!data.discord && !!misc"
      :discord="data.discord"
      :price="price"
      :misc="misc.lxvx"
      :weekGap="misc.weekGap"
      :funding-rate="misc['funding-rate']"
    />
    <funding-rate :fundingData="data.exchange.funding" />

    <future :future="data.future || {}" />

    <OI :xChart="data['x-chart']" />

    <statistic :data="data" />

    <bar-card>
      <div class="flex flex-wrap justify-center">
        <router-link class="title md:text-xl" to="/markets">
          Yahoo Stock
        </router-link>
      </div>
      <div class="bar-body my-1 sm:my-2">
        <div
          class="bar-item cursor-pointer"
          v-for="(code, index) in items"
          :key="index"
          @click="toYahoo(code)"
        >
          <template v-if="code !== 'updated'">
            <div class="font-bold">{{ code }}</div>
            <div class="ml-auto">{{ yahoo(data.yahoo.stock[code]) }}</div>
          </template>
        </div>
      </div>
    </bar-card>
  </div>
</template>

<script>
import LongShort from './LongShort.vue'
import CryptoQuant from './CryptoQuant.vue'
import OI from './OI.vue'
import BarCard from '@/components/markets/BarCard'
import ExchangesPrice from './ExchangesPrice.vue'
import FundingRate from './FundingRate.vue'
import IvBand from './IvBand.vue'
import Future from './Future.vue'
import { _calc } from './index.js'
import Statistic from '@/components/markets/MarketBar/Statistic'

const lsKey = 'MARKET_BAR'

export default {
  name: 'MarketBar',
  components: {
    Statistic,
    OI,
    BarCard,
    IvBand,
    Future,
    LongShort,
    FundingRate,
    CryptoQuant,
    ExchangesPrice,
  },
  data: () => ({
    misc: {},
    data: {},
    interval: null,
  }),
  created() {
    // get cache for fast displaying data
    try {
      const data = localStorage.getItem(lsKey)
      this.data = JSON.parse(data)
    } catch (err) {
      this.data = null
    }
    this.getMisc()
  },
  mounted() {
    this.interval = setInterval(this.getMisc, 10e3)
  },
  methods: {
    formatBinanceAccount({ curent, ratio }) {
      // https://thanmtsvcb.slack.com/archives/DL97S0GUB/p1618814151010200
      return `
      <div class="font-bold flex">
        <span class="underline">
          Day:
        </span>
        <span class="ml-auto">
          &nbsp${ratio}%
        </span>
      </div>

      <div class="font-bold flex">
        <span class="underline">
          Long account:
        </span>
        <span class="ml-auto">
          &nbsp${curent}%
        </span>
      </div>`
    },
    _date(ts, weekday) {
      const date = new Date(ts)
      const month = 1 + date.getMonth()
      const day = date.getDate()
      const hour = date.getHours()
      return `(${month}/${day}) ${weekday} ${hour}:00`
    },
    async getMisc() {
      try {
        const data = await this.$http.get('/api/market/misc')
        const misc = await this.$http.get('/api/v1/markets/misc')
        localStorage.setItem(lsKey, JSON.stringify(this.data))
        this.data = data
        this.misc = misc
      } catch (err) {
        // ignore
      }
    },
    yahoo(val) {
      if (typeof val === 'string') {
        return `$${now}`
      }
      const { now, prev } = val
      const rate = ((100.0 * now) / prev - 100).toFixed(2)
      return `$${now} (${rate}%)`
    },
    toYahoo(code) {
      return window.open(`https://finance.yahoo.com/quote/${code}`, '_blank')
    },
  },
  computed: {
    items() {
      return Object.keys(this.data.yahoo.stock).filter(
        (item) => item !== 'updated'
      )
    },
    bybitPrices() {
      const prices = this.data.bybit.prices
      const diff = prices.last - prices.mark
      return {
        ...prices,
        diff,
        class: _calc(-diff, { mid: 0, diff: 300 }).class,
      }
    },
    price() {
      return this.data.price
    },
  },
  destroyed() {
    clearInterval(this.interval)
  },
}
</script>

<style lang="css" src="./style.css" scoped />
